<template>
  <card-component class=" has-mobile-sort-spaced" :has-button-slot="true" title="Users" icon="account-multiple">
    <div slot="button" class="buttons">
      <b-button type="is-grey" size="is-small" icon-left="plus-circle" @click="createModal">
        Create
      </b-button>
      <refresh-button slot="button" :isLoading="loading" @button-click="fetchData"/>
    </div>
    <b-table
        :data="users"
        :loading="loading"
        :striped="true"
        :hoverable="true"
        searchable>

      <b-table-column class="has-no-head-mobile is-image-cell" v-slot="props">
        <div class="image">
          <user-avatar :email="props.row.email"/>
        </div>
      </b-table-column>
      <b-table-column field="name" label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="email" label="Email" sortable v-slot="props">
        <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
      </b-table-column>

      <b-table-column field="created_at" label="Created" sortable v-slot="props">
        {{ props.row.created_at | moment("from", "now") }}
      </b-table-column>

      <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">

          <b-button icon-left="eye" size="is-small" type="is-success"
                    @click="$router.push({name: 'users.detail', params: { id: props.row.id}})"></b-button>

          <b-dropdown v-can="'manage all users'" append-to-body aria-role="list" position="is-bottom-left">
            <b-button icon-left="menu" size="is-small" type="is-primary" slot="trigger" slot-scope="{ active }">
            </b-button>

            <b-dropdown-item  aria-role="listitem">
              <div class="media" @click="deleteModal(props.row.id)">
                <b-icon class="media-left" icon="delete"></b-icon>
                <div class="media-content">
                  <h3>Remove</h3>
                </div>
              </div>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </b-table-column>

    </b-table>
  </card-component>
</template>

<script>
import users from "@/store/modules/users.store"
import CardComponent from "@/components/CardComponent";
import RefreshButton from '@/components/RefreshButton'
import UserAvatar from "../UserAvatar";
import NewUserComponent from "@/components/users/NewUserComponent";

export default {
  name: "UsersTableComponent",
  components: {UserAvatar, CardComponent, RefreshButton, NewUserComponent},

  data() {
    return {
      loading: true,
      total: 0,
      page: 1,
      per_page: 10,
      sort_field: "",
      sort_order: "asc",
    }
  },

  created() {
    if (!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    users() {
      return this.$store.state.users.users
    }
  },

  methods: {

    fetchData() {
      this.loading = true

      const params = [
        //     'include=',
        //     `page=${this.page}`,
        //     `per_page=${this.per_page}`,
        //     `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,
        //
      ].join('&')
      // console.log(params)

      this.$store.dispatch('users/fetchUsers', params)
          .then(response => {
            this.loading = false
          })
    },

    deleteModal(id) {
      this.$buefy.dialog.confirm({
        title: 'Removing User',
        message: 'Are you sure you want to <b>remove</b> this user? This action cannot be undone.',
        confirmText: 'Remove User',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('users/deleteUser', {user_id: id})
              .then(response => {
                this.$buefy.toast.open('User removed!')
              }).catch(err => {
            this.$buefy.toast.open({
              message: err,
              type: 'is-danger'
            })
          })
        }
      })
    },
    createModal() {
      this.$buefy.modal.open({
        parent: this,
        component: NewUserComponent,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },

    submit() {
      this.$store.dispatch('users/registerUser', this.user)
          .then(response => {
            this.$parent.close()
            this.$buefy.toast.open({
              message: 'User created',
              type: 'is-success'
            })

          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
      })
    }

  }


}
</script>

<style scoped>

</style>