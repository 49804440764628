<template>
    <card-component title="AIBoxes" :is-scrollable="false" :has-button-slot="true" :has-footer-slot="true" @ps-ready="psReady">
<!--        <refresh-button slot="button" :is-loading="isLoading" @button-click="delayedFetch"/>-->

        <div class="media-list">
            <b-loading :is-full-page="false" :active="isLoading"/>

            <div  class="media" v-for="aibox in this.aiboxes">

                <div class="media-content">
                    <div class="content">
                        <p class="media-meta">
                            <nav class="level is-mobile">
                                <div class="level-left">
                                    <strong class="level-item">{{ aibox.id }} | {{ aibox.name }}</strong>
                                </div>
                                <div class="level-right">
                                    <b-button  size="is-small" type="is-grey" icon-left="link-variant-off" class="level-item" @click="detachModal(aibox.id)">

                                    </b-button>
                                </div>
                            </nav>
                        </p>
                    </div>
                    <nav class="level is-mobile">
                        <div class="level-left" style="max-width: 85%">

                            <p>MAC: <b>{{ aibox.mac }}</b> &nbsp; &nbsp;|&nbsp;&nbsp; Prom: <b>TODO</b> &nbsp; |  &nbsp; AIBox version:<b> TODO </b></p>

                        </div>
                        <div class="level-right">
                            <b-button  size="is-small" type="is-success" icon-left="pencil" class="level-item" @click="formModal(aibox.id)">

                            </b-button>
                        </div>

                    </nav>


                  <div class="buttons">

                      <b-button size="is-small" :loading="syncLoading" icon-left="sync" @click="sync(aibox.id)">sync</b-button>

                      <b-button size="is-small" :loading="rebootLoading" icon-left="restart" @click="reboot(aibox.id)">reboot</b-button>

                    </div>


                </div>
            </div>
            <div v-if="this.aiboxes.length === 0">
                <p class="has-text-centered">
                    <b-icon icon="cactus" size="is-large" />
                </p>
                <p class="has-text-centered">No linked AIBoxes found</p>
            </div>
        </div>


<!--        <a class="card-footer-item" slot="footer" @click="attachModal(package_id)">-->
<!--            <b-icon icon="plus-circle" custom-size="default"/>-->
<!--            <span>Attach alarm</span>-->
<!--        </a>-->

        <div class="card-footer-item card-footer" slot="footer" >
            <small class="px-2 pb-1"> The aibox will be visible once its worker connects to the Portal using the nas key. </small>
        </div>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/CardComponent'
    import CardToolbar from '@/components/CardToolbar'
    import AIBoxFormComponent from "./AIBoxFormComponent";
    import devices from "@/store/modules/devices.store"


    export default {
        name: 'AIBoxListComponent',
        components: { CardToolbar, CardComponent },
        props: ['aiboxes'],
        data () {
            return {
                isLoading: false,

                //Autofill
                alarmname: '',
                alarm_id: "",

                // Disconnect/connect
                connectLoading: false,
                disconnectLoading: false,
                rebootLoading: false,
                syncLoading: false,
                self: this,

            }
        },
        created() {
            if(!devices.isRegistered) {
                this.$store.registerModule('devices', devices)
                devices.isRegistered = true
            }
        },

        computed: {

        },
        mounted() {
            // this.$store.dispatch('devices/fetchAlarms', 'include=package,riscorunner.nas')
        },
        methods: {
            psReady (ps) {
                this.ps = ps
            },
            detachModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Detach Alarm',
                    message: 'Are you sure you want to <b>detach</b> this aibox? Existing aiboxes and Alerts wont be visible anymore.',
                    confirmText: 'Detach Alarm',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => this.detachAlarm(id)
                })
            },
            detachAlarm(id) {
                this.$store.dispatch('devices/updateAlarm', {id: id, package_id: null, nas_id: null } )
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Alarm attached',
                            type: 'is-info',
                            queue: false
                        })
                    })
            },

            formModal(id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: AIBoxFormComponent,
                    props: {
                        id: id
                    },
                    events: {
                      update: () =>  {
                        this.$emit('update')
                      }
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            reboot(id) {
              this.rebootLoading = true
              this.$store.dispatch('devices/rebootAIBox', id)
                  .then(response => {
                    this.$buefy.snackbar.open({
                      message: 'Worker rebooted',
                      type: 'is-success',
                      position: 'is-top',
                      actionText: 'OK',
                      indefinite: true,
                    })
                    this.rebootLoading = false

                  }).catch(err => {
                this.$buefy.snackbar.open({
                  message: err,
                  type: 'is-danger',
                  position: 'is-top',
                  actionText: 'OK',
                  indefinite: true,
                })
                this.rebootLoading = false

              })
            },
            sync(id) {
                this.syncLoading = true
                this.$store.dispatch('devices/syncAIBox', id)
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'AIBOX synchronized',
                            type: 'is-success'
                        })
                        this.syncLoading = false

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                    this.syncLoading = false

                })
            },
        }
    }
</script>

<style lang="scss">

</style>
