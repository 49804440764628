
<template>
    <section class="section is-main-section">
        <title-bar :title-stack="titleStack"/>

        <PackageTableComponent :active_only="true"/>
    </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import PackageTableComponent from "../components/package/PackageTableComponent";
import TitleBar from "../components/TitleBar";

export default {
  name: 'Packages',
  components: {TitleBar, PackageTableComponent, CardComponent },
    data () {
        return {


        }
    },
  computed: {
    titleStack () {
      return [
        'Towers & Sets'
      ]
    },

  },

}
</script>
