<template>
    <section class="section is-main-section">
        <TitleBar :title-stack="titleStack"></TitleBar>
        <div v-if="company" class="columns" >
            <div class="column is-half">
                <card-component icon="map-marker" title="Projects">
                    <template v-if="company.projects.length > 0">
                        <project-item-component :projects="company.projects" :status="false" columns="column is-full"/>
                    </template>
                    <template v-else>
                        <p class="has-text-centered">
                            <b-icon icon="cactus" size="is-large" />
                        </p>
                        <p class="has-text-centered">No projects linked&hellip;</p>
                    </template>

                </card-component>
            </div>
            <div class="column is-half">
                <card-component title="Users" icon="account-multiple" v-bind:class="{'has-table': company.users.length > 0}">
                    <template v-if="company.users.length > 0">
                        <company-users :users="company.users"/>
                    </template>
                    <template v-else>
                        <p class="has-text-centered">
                            <b-icon icon="cactus" size="is-large" />
                        </p>
                        <p class="has-text-centered">No users linked&hellip;</p>
                    </template>

                </card-component>

            </div>

            <div class="column is-half" >
            </div>
        </div>
    </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "@/components/TitleBar";
import UsersTableComponent from "@/components/users/UsersTableComponent";
import UserAvatar from "../../components/UserAvatar";
import HeroBar from "../../components/HeroBar";
import users from "@/store/modules/users.store"
import CompanyUsers from "../../components/companies/CompanyUsers";
import ProjectItemComponent from "../../components/project/ProjectItemComponent";

export default {
  name: 'CompanyDetail',
  components: {ProjectItemComponent, CompanyUsers, HeroBar, UserAvatar, UsersTableComponent, TitleBar, CardComponent },

    data() {
      return {
          company: null,
          titleStack: ["Admin", "Companies"]



      }
    },

  computed: {
      id () {
          return parseInt(this.$route.params.id)
      },

  },
    created() {
        if(!users.isRegistered) {
            this.$store.registerModule('users', users)
            users.isRegistered = true
        }
    },

    mounted() {
        this.fetchData()
    },
    methods: {

        fetchData() {
            this.loading = true

            const payload = {
                id: this.id,
                params: 'include=users,projects'
            }

            this.$store.dispatch('users/fetchCompanyDetails', payload)
                .then(response => {
                    this.company = response.data
                    this.titleStack.push(this.company.name)
                    this.loading = false
                })
        },

    }

    }
</script>
