<template>
  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>


    <div class="tile is-ancestor is-vertical " v-if="this.pkgs.length > 0">

      <div v-for="the_package in this.pkgs">
        <div class="tile is-parent pb-0" v-if="the_package.project && the_package.cameras.length > 0">
          <div class="tile is-child">

            <div class=" level is-mobile mb-2 pl-1">
              <div class=" level-left">
                <p class="level-item has-text-left mb-0 py-0">
                  <b-icon size="is-small" class="mr-2" icon="eye"></b-icon>
                  <span style="margin-right:  0.7rem;" class="title is-5">{{
                      the_package.name
                    }} - {{ the_package.project.name }}</span>
                </p>
                <StatusLineComponent :status="the_package.status"></StatusLineComponent>
              </div>
              <div class="level-right">
                <p class="level-item py-0">
                  <small class="is-5">{{ the_package.cameras.length }} cameras</small>
                </p>
              </div>
            </div>
          </div>
        </div>


        <div v-if="the_package.type !== 'anpr'" class="tile is-parent pt-0" style="max-width: 95vw">
          <b-carousel-list class="tile is-child"
                           v-if="the_package" :data="the_package.cameras" :items-to-show="Math.max(3, the_package.cameras.length)">
            <template slot="item" slot-scope="camera">

              <camera-snapshot-component :project_id="the_package.project.id" :camera_id="camera.id" :name="camera.name"
                                         :thumb="true"></camera-snapshot-component>

            </template>
          </b-carousel-list>
          <div class="tile is-child card" v-else>

            <b-skeleton height="15vw"></b-skeleton>

          </div>
        </div>


      </div>


    </div>
  </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "../components/TitleBar";
import packages from "@/store/modules/packages.store";
import CardToolbar from "@/components/CardToolbar";
import CameraSnapshotComponent from "@/components/devices/camera/CameraSnapshotComponent.vue";
import {isMobile} from "buefy";
import StatusLineComponent from "@/components/StatusLineComponent.vue";

export default {
  name: 'Livefeed',
  components: {StatusLineComponent, CameraSnapshotComponent, CardToolbar, TitleBar, CardComponent},
  data() {
    return {
      data: [],
      loading: true,
      pkgs: [],
      total: 0,
      page: 1,
      per_page: 50,
      sort_field: "",
      sort_order: "asc",
    }
  },

  created() {
    if (!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
  },
  computed: {
    titleStack() {
      return [
        'Admin',
        'Livefeed'
      ]
    },

  },

  mounted() {
    console.log("ImHere")
    this.fetchData()
  },

  methods: {
    isMobile() {
      return isMobile
    },

    fetchData() {
      this.loading = true;
      // this.$store.dispatch('mapbox/setHeader', {
      //   stack: null,
      //   status: null
      // })
      const params = [
        'include=cameras,project',
        //`sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,
        `filter[active]=${!this.active_only}`,
      ].join('&')

      this.$store.dispatch('packages/fetchPackages', params)
        .then(response => {
          if (response.data.data.length > 0) {
            this.pkgs = response.data.data
            console.log(response.data.data)
            //const geo = GeoJSON.parse(response.data.data, {Point: ['lat', 'long']});
            //this.$store.dispatch('mapbox/drawPackages', {ctx: this, geo: geo, packages: response.data.data, snap: true})
          }

          this.loading = false
        })
    }
  }

}
</script>
