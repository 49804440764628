<template>
  <card-component title="Cameras" :is-scrollable="false" :has-button-slot="true" :has-footer-slot="true">
    <refresh-button slot="button" :is-loading="isLoading" label="Sync" @button-click="syncCameras"/>

    <div class="media-list">
      <b-loading :is-full-page="false" :active="isLoading"/>

      <div class="media" v-for="(camera, index) in this.cameras" :key="camera.id">

        <div class="media-content">
          <div class="content">
            <p class="media-meta">
            <nav class="level is-mobile">
              <div class="level-left">
                <strong class="level-item">{{ camera.name }} </strong>
                <b-tag v-if="camera.nas_id == null" type="is-success is-light" rounded size="small">CH{{ camera.ch }}</b-tag>
                <b-tag rounded size="small">{{ camera.type }}</b-tag>
              </div>
              <div class="level-right">
                <b-button size="is-small" type="is-grey" icon-left="link-variant-off" class="level-item"
                          @click="detachModal(camera.id)">

                </b-button>
              </div>
            </nav>
            </p>


          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              {{ camera.ip }}
            </div>
            <div class="level-right">
              <b-button size="is-small" type="is-success" icon-left="pencil" class="level-item"
                        @click="formModal(camera.id)">

              </b-button>
            </div>
          </nav>

          <div class="buttons">
            <b-button size="is-small" :loading="camera.loading" icon-left="sync" @click="sync(camera.id, index)">sync
              nimble stream
            </b-button>
          </div>

        </div>
      </div>
      <div v-if="this.cameras.length === 0">
        <p class="has-text-centered">
          <b-icon icon="cactus" size="is-large"/>
        </p>
        <p class="has-text-centered">No linked camera's found</p>
      </div>
    </div>

    <div class="card-footer-item card-footer" slot="footer">
      <small class="px-2 pb-1"> Cameras can be found once they are linked to Surveillance Station or AIBOX. Press "Sync" to check
        all linked nases/AIBoxes for newly added cameras.
      </small>

    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import CardToolbar from '@/components/CardToolbar'
import CameraFormComponent from "./CameraFormComponent";
import devices from "@/store/modules/devices.store"
import RefreshButton from "../../RefreshButton";
import Vue from "vue";


export default {
  name: 'CameraListComponent',
  components: {RefreshButton, CardToolbar, CardComponent},
  props: ['cameras', 'nases'],
  data() {
    return {
      syncCounter: 0,
    }
  },
  created() {
    if (!devices.isRegistered) {
      this.$store.registerModule('devices', devices)
      devices.isRegistered = true
    }
  },

  computed: {
    isLoading() {
      return this.syncCounter !== 0
    }

  },
  mounted() {

  },
  methods: {

    detachModal(id) {
      this.$buefy.dialog.confirm({
        title: 'Detach Camera',
        message: 'Are you sure you want to <b>detach</b> this camera? Existing Alarms and Alerts wont be visible anymore.',
        confirmText: 'Detach Camera',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.detachCamera(id)
      })
    },

    detachCamera(id) {
      this.$store.dispatch('devices/updateCamera', {id: id, nas_id: null, package_id: null})
          .then(response => {
            this.$buefy.toast.open({
              message: 'Camera detached',
              type: 'is-success',
              queue: false
            })
            this.$emit('update');

          })
    },
    formModal(id) {
      this.$buefy.modal.open({
        parent: this,
        component: CameraFormComponent,
        props: {
          id: id
        },
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },

    syncCameras() {
      this.nases.forEach(nas => {
        this.syncCounter += 1
        this.$store.dispatch('devices/syncCameras', nas.id)
            .then(response => {
              this.$buefy.toast.open({
                message: `Nas ${nas.id} synced`,
                type: 'is-success',
                queue: false,
              })
              this.$emit('update');
              this.syncCounter -= 1


            }).catch(err => {
          this.$buefy.toast.open({
            message: `Could not reach nas ${nas.id}`,
            type: 'is-danger',
            queue: false,
          })
          this.syncCounter -= 1

        })
      })

    },

    newCamera() {
      this.$buefy.modal.open({
        parent: this,
        component: CameraFormComponent,
        props: {
          id: null
        },
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    submitNewCamera(value) {
      this.$store.dispatch('users/createCompany', value)
          .then(response => {
            this.$buefy.toast.open({
              message: 'Company created',
              type: 'is-success'
            })

            this.$refs.autocomplete.setSelected(response.data)

          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
      })
    },

    sync(id, index) {
      Vue.set(this.cameras[index], 'loading', true);
      this.$store.dispatch('devices/syncCameraStream', id)
          .then(response => {
            if (response.data.success) {
              this.$buefy.toast.open({
                message: 'Camera stream sync dispatched, this can take up to one minute.',
                type: 'is-success',
              })
              Vue.set(this.cameras[index], 'loading', false);
            } else {
              this.$buefy.toast.open({
                message: 'Stream could not be synced. Contact an administrator.',
                type: 'is-danger'
              })
              Vue.set(this.cameras[index], 'loading', false);
            }

          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
        Vue.set(this.cameras[index], 'loading', false);
      })
    },
  }
}
</script>
