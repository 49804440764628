<template>
  <card-component class=" has-mobile-sort-spaced" :has-button-slot="true" title="Role Presets" icon="lock">
    <b-message type="is-info is-light">Role presets can be added for the client part of the application.
    Note that changing a preset does not affect existing users' roles.
    </b-message>

    <div slot="button" class="buttons">
      <b-button @click="createModal(null)" type="is-grey" size="is-small" icon-left="plus-circle">
        New Preset
      </b-button>
    </div>

    <b-table v-if="roles"
             :data="roles"
             :loading="loading"
             :hoverable="true"
             :key="presets.map(p => p.id).join()"
             sticky-header
             height="55vh"
    >
      <b-table-column field="permission"
                      sticky
                      width="250"
      >
        <template v-slot="props">
          <article class="media">
            <div class="media-content">
              <div class="media-meta">
                <p>
                  <strong>{{ props.row.role }}</strong>
                </p>
                <p class="text is-size-7">
                  {{ props.row.description }}
                </p>
              </div>
            </div>
          </article>
        </template>
      </b-table-column>
      <b-table-column v-for="preset in presets"
                      :key="preset.id"
                      :field="preset.name"
                      :label="preset.name"
                      centered
                      cell-class="vcenter"
      >

        <template v-slot:header="{ column }">
          <article class="media has-text-left">
            <div class="media-content">
              <div class=" media-meta">
                <p class=" mb-0">
                  <strong>{{ preset.name }}</strong>
                </p>
                <p class="text is-size-7 has-text-weight-light">
                  {{ preset.description }}
                </p>

              </div>
            </div>
            <div class="media-right ml-0">
              <a @click="confirmRemovePreset(preset)">
                <b-icon size="is-small" icon="close-circle"></b-icon>
              </a>
            </div>
          </article>
        </template>
        <template v-slot="props">
          <div class="field  has-text-centered">
            <b-checkbox :value="preset.roles.filter(_role => _role.id === props.row.id).length > 0"
                        @input="(value) => updateRole(value, props.row, preset, )">
            </b-checkbox>
          </div>
        </template>
      </b-table-column>


      <div class="has-text-right" slot="footer">
        <br>
        <b-field>

        </b-field>
      </div>


    </b-table>
  </card-component>
</template>

<script>
import UserAvatar from "../UserAvatar";
import users from "@/store/modules/users.store"
import CardComponent from "../CardComponent";

export default {
  name: "PresetTable",
  props: [""],
  components: {CardComponent, UserAvatar},
  data() {
    return {
      presets: [],
      roles: [],
      loading: true,


    }
  },
  computed: {},
  created() {
    if (!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      this.$store.dispatch('users/fetchRoles')
          .then(response => {
            this.roles = response.data.data
            this.loading = false;
          })
      this.$store.dispatch('users/fetchPresets').then(response => {
        this.presets = response.data.data
      })
    },

    updateRole(value, role, preset) {

      if (value) {
        preset.roles.push(role)
      } else {
        let index = preset.roles.findIndex(r => r.id === role.id)
        preset.roles.splice(index, 1)
      }

      this.$store.dispatch('users/updatePresetRoles', {
        data: {roles: preset.roles.map(a => a.id)},
        preset_id: preset.id
      }).then(response => {

        this.$buefy.toast.open({
          type: "is-success",
          message: "Preset updated",
          queue: false,
        })
        this.fetchData();
      })


    },

    createModal() {
      this.$buefy.dialog.prompt({
        message: `What's the preset name?`,
        inputAttrs: {
          placeholder: 'e.g. PM',
          maxlength: 50
        },
        trapFocus: true,
        onConfirm: (value) => this.submit(value)
      })
    },
    submit(value) {
      this.$store.dispatch('users/createPreset', value)
          .then(response => {
            this.$buefy.toast.open({
              message: 'Preset created',
              type: 'is-success'
            })
            this.fetchData();


          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
      })
    },

    confirmRemovePreset(preset) {
      let thisIns = this;
      this.$buefy.dialog.confirm({
        title: 'Remove preset',
        message: `Are you sure you want to <b>remove</b> preset ${preset.name}? This wont affect any role changes on existing users.`,
        confirmText: 'Remove preset',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('users/removePreset', {
                preset_id: preset.id
              }
          ).then(response => {
            this.$buefy.toast.open({
              type: "is-success",
              message: "Preset removed."
            })
            thisIns.fetchData();
          })
        }
      })
    },

  }
}
</script>

<style lang="scss">

  .b-table {
    .table {
      td.vcenter {
        vertical-align: middle;
      }
    }
  }

</style>