<template>
<div v-if="project">
    <section class="section is-main-section">
        <TitleBar :title-stack="titleStack"/>

      <div class="columns  is-multiline">
        <div class="column ">

          <div class="tile is-ancestor is-vertical">
            <div class="tile is-parent">
                <project-form-component :id="project.id" :popup="false" class="tile is-child"></project-form-component>
            </div>
          </div>

        </div>
        <div class="column ">
          <div class="tile is-ancestor is-vertical">

            <div v-can="'manage all packages'"  class="tile is-parent">
              <card-component title="Actions" class="tile is-child ">
                <div class="buttons">
                  <b-button icon-left="delete" @click="deleteModal">Delete project</b-button>
                </div>
              </card-component>
            </div>

            <div class="tile is-parent">
              <card-component title="Current Packages" :has-button-slot="true" class="tile is-child ">
                <div slot="button" class="buttons">
                  <b-button v-can="'manage all packages'" @click="attachPackage" type="is-grey" size="is-small" icon-left="plus-circle">
                    Attach package
                  </b-button>
                </div>
                <div class="media-list">
                  <div class="media" v-for="a_package in project.packages"  style="cursor:pointer" @click="$router.push({ name:'package.detail', params: {project_id: project.id, id: a_package.id} })">
                    <div class="media-left">
                      <eye-icon class="pt-2" :status="a_package.status" type="project" :has-text="false"/>
                    </div>
                    <div class="media-content has-text-left">
                      <div class="text has-text-weight-bold is-6">{{ a_package.name }}</div>
                      <!--                            <p class="subtitle is-6">{{ project.address }} {{ project.nr }} {{ project.town }}</p>-->
                      <!--                <status-component :status="a_package.status"/>-->
                      <div class="level">
                        <div class="level-left">
                          <status-line-component :status="a_package.status"></status-line-component>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div v-if="project.packages.length === 0" class="media">
                    <div class="media-content">
                      <p class="has-text-centered">No packages linked yet</p>
                    </div>
                  </div>
                </div>

              </card-component>

            </div>
          </div>
        </div>
      </div>
          <div class="tile is-ancestor is-vertical">

            <div class="tile is-parent">
                <card-component title="Permissions" class="tile is-child">

                            <project-permission-table :project="project">

                            </project-permission-table>

                </card-component>
            </div>
          </div>

    </section>
  </div>

</template>

<script>
import projects from "@/store/modules/projects.store"
import CardComponent from '@/components/CardComponent'
import ProjectPermissionTable from "../components/project/ProjectPermissionTable";
import LinkPackageComponent from "../components/package/LinkPackageComponent";
import EyeIcon from "../components/EyeIcon";
import TitleBar from "../components/TitleBar";
import ProjectFormComponent from "@/components/project/ProjectFormComponent";
import StatusLineComponent from "@/components/StatusLineComponent";




const GeoJSON = require('geojson');


export default {
  name: 'ProjectEdit',
  components: {
    StatusLineComponent,
    ProjectFormComponent,
      TitleBar,
      EyeIcon,
      CardComponent,
      ProjectPermissionTable,
      },
    data () {
        return {
            project: null,
        }
    },
  computed: {

    titleStack () {
      return [
        'Project',
          'Edit'
      ]
    },
      id () {
        return parseInt(this.$route.params.id)
      },

      //...mapGetters({
      //    currentSite: 'sites/getSiteById'
      //})
  },


    created() {
        if(!projects.isRegistered) {
            this.$store.registerModule('projects', projects)
          projects.isRegistered = true
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {

        fetchData() {
          let formData = {
              id: this.id,
              params: ""
          }

            this.$store.dispatch('projects/fetchProjectDetails', formData)
                .then(response => {
                    this.project = response.data.data
                })
        },

        attachPackage() {
          let thisIns = this
          this.$buefy.modal.open({
            parent: this,
            component: LinkPackageComponent,
            props: {
              project: this.project
            },
            ariaRole: "dialog",
            ariaModal: true,
            hasModalCard: true,
            customClass: 'custom-class custom-class-2',
            trapFocus: false,
          })
        },

      deleteModal() {
        this.$buefy.dialog.confirm({
          title: 'Removing Project',
          message: 'Are you sure you want to <b>remove</b> this project? This action cannot be undone. Linked packages' +
              ' will be unlinked and alerts will be deleted.',
          confirmText: 'Remove Project',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.deleteProject()

          }
        })
      },

      deleteProject() {
        this.$store.dispatch('projects/deleteProject', this.project.id)
            .then(response => {
              this.$buefy.toast.open('Project deleted')
              this.$router.push({ name:'projects'})

            })

      },

    }
}
</script>

<style lang="scss">
    .editor__content {
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        border: 1px solid #dbdbdb;
        display: block;
        max-width: 100%;
        min-width: 100%;
        padding: 0.625em;
        resize: vertical;
        background-color: white;
        border-radius: 4px;
        min-height: 100px;
    }
    .ProseMirror:focus {
        outline: none;
    }

    .textarea {
        resize: vertical;
        height: 100%;
    }

    @import "src/scss/theme-default";

    ul[data-type="todo_list"] {
        padding-left: 0;
    }
    li[data-type="todo_item"] {
        display: flex;
        flex-direction: row;
    }
    .todo-checkbox {
        border: 2px solid $grey-dark;
        height: 0.9em;
        width: 0.9em;
        box-sizing: border-box;
        margin-right: 10px;
        margin-top: 0.3rem;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        border-radius: 0.2em;
        background-color: transparent;
        transition: 0.4s background;
    }
    .todo-content {
        flex: 1;
        > p:last-of-type {
            margin-bottom: 0;
        }
        > ul[data-type="todo_list"] {
            margin: .5rem 0;
        }
    }
    li[data-done="true"] {
        > .todo-content {
            > p {
                text-decoration: line-through;
            }
        }
        > .todo-checkbox {
            background-color: $grey-dark;
        }
    }
    li[data-done="false"] {
        text-decoration: none;
    }


</style>