<template>
    <card-component title="Attach Nas"
                    icon="link"
                    :is-scrollable="true">
        <form @submit.prevent="submit">

            <div  class="media" v-for="nas in this.nas">

                <div class="media-content">
                    <div class="content">
                        <p class="media-meta">
                            <nav class="level is-mobile">
                                <div class="level-left">
                                    <strong class="level-item">{{ nas.ip }}</strong>
                                    <b-tag rounded size="small">{{ nas.type }}</b-tag>
                                </div>
                                <div class="level-right">
                                </div>
                            </nav>
                        </p>


                    </div>
                    <nav class="level is-mobile">
                        <div class="level-left">
                            <a :href="nas.synodns + ':' + nas.port" class="level-item text is-size-6">
                                {{ nas.synodns }}
                            </a>
                        </div>
                        <div class="level-right">
                            <b-button  size="is-small" type="is-success" icon-left="link" class="level-item" @click="submit(nas.id)">
                                Choose Nas
                            </b-button>
                        </div>

                    </nav>
                </div>
            </div>

            <hr>

        </form>
    </card-component>
</template>

<script>


    import CardComponent from "@/components/CardComponent";

    export default {
        name: "SelectNasComponent",
        components: {CardComponent},
        props: {
            package_id: {
                default: null
            },
            alarm_id: {
                default: null
            },
        },

        data() {
            return {

            }
        },

        computed: {
            creating () {
                return this.id === null
            },
            nas() {
                return this.$store.getters["devices/getNasByPackageId"](this.package_id)
            },

        },


        mounted() {

        },

        methods: {

            submit(nasid) {
                this.$store.dispatch('devices/updateAlarm', {id: this.alarm_id, nas_id: nasid, package_id: this.package_id} )
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Nas attached',
                            type: 'is-info',
                            queue: false
                        })
                        this.$parent.close()
                    })
            }
        }

    }
</script>

<style scoped>

</style>