<template>
    <card-component title="Risco Alarms" :is-scrollable="false" :has-button-slot="true" :has-footer-slot="true" @ps-ready="psReady">
<!--        <refresh-button slot="button" :is-loading="isLoading" @button-click="delayedFetch"/>-->

        <div class="media-list">
            <b-loading :is-full-page="false" :active="isLoading"/>

            <div  class="media" v-for="alarm in this.alarms">

                <div class="media-content">
                    <div class="content">
                        <p class="media-meta">
                            <nav class="level is-mobile">
                                <div class="level-left">
                                    <strong class="level-item">{{ alarm.id }} | {{ alarm.name }}</strong>
                                    <b-tag rounded  class="level-item" size="small">{{ alarm.type }}</b-tag>

                                </div>
                                <div class="level-right">
                                    <b-button  size="is-small" type="is-grey" icon-left="link-variant-off" class="level-item" @click="detachModal(alarm.id)">

                                    </b-button>
                                </div>
                            </nav>
                        </p>
                    </div>
                    <nav class="level is-mobile">
                        <div class="level-left" style="max-width: 85%">

                            <p>Panel id: <b>{{ alarm.panel_id }}</b> &nbsp; &nbsp;|&nbsp;&nbsp; Prom: <b>{{ alarm.promnumber }} </b> &nbsp; |  &nbsp; Panel version:<b> {{ alarm.panel_version }} </b></p>

                        </div>
                        <div class="level-right">
                            <b-button  size="is-small" type="is-success" icon-left="pencil" class="level-item" @click="formModal(alarm.id)">

                            </b-button>
                        </div>

                    </nav>


                  <div class="buttons">

                      <b-button size="is-small" :loading="syncLoading" icon-left="sync" @click="sync(alarm.id)">sync</b-button>

                      <b-button size="is-small" :loading="disconnectLoading" icon-left="lan-disconnect" @click="disconnect(alarm.id)">disconnect</b-button>

                      <b-button size="is-small" :loading="connectLoading" icon-left="lan-connect" @click="connect(alarm.id)">connect</b-button>

                      <b-button size="is-small" :loading="rebootLoading" icon-left="restart" @click="reboot(alarm.id)">reboot</b-button>

                    </div>


                </div>
            </div>
            <div v-if="this.alarms.length === 0">
                <p class="has-text-centered">
                    <b-icon icon="cactus" size="is-large" />
                </p>
                <p class="has-text-centered">No linked alarms found</p>
            </div>
        </div>


<!--        <a class="card-footer-item" slot="footer" @click="attachModal(package_id)">-->
<!--            <b-icon icon="plus-circle" custom-size="default"/>-->
<!--            <span>Attach alarm</span>-->
<!--        </a>-->

        <div class="card-footer-item card-footer" slot="footer" >
<!--            <b-field>-->
<!--                <b-autocomplete-->
<!--                        v-model="alarmname"-->
<!--                        placeholder="Attach alarm..."-->
<!--                        ref="autocomplete"-->
<!--                        :open-on-focus="true"-->
<!--                        :data="filteredDataObj"-->
<!--                        icon="plus-circle"-->
<!--                        field="name"-->
<!--                        @select="option => alarm_id = option.id">-->
<!--                    <template slot="header">-->
<!--                        <a  @click="newAlarm">-->
<!--                            <span> Add new... </span>-->
<!--                        </a>-->
<!--                    </template>-->
<!--                    <template slot="empty">No results for {{alarmname}}</template>-->

<!--                </b-autocomplete>-->
<!--                <p class="control">-->
<!--                    <b-button :disabled="alarm_id === ''" icon-left="link" class="button is-primary"-->
<!--                              @click="attachAlarm(alarm_id)"/>-->
<!--                </p>-->
<!--            </b-field>-->
            <small class="px-2 pb-1"> The alarms will be visible once it connects to the Portal using the nas key.
            </small>
        </div>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/CardComponent'
    import CardToolbar from '@/components/CardToolbar'
    import AlarmFormComponent from "./AlarmFormComponent";
    import devices from "@/store/modules/devices.store"
    import SelectNasComponent from "../alarm/SelectNasComponent";


    export default {
        name: 'AlarmListComponent',
        components: { CardToolbar, CardComponent },
        props: ['alarms'],
        data () {
            return {
                isLoading: false,

                //Autofill
                alarmname: '',
                alarm_id: "",

                // Disconnect/connect
                connectLoading: false,
                disconnectLoading: false,
                rebootLoading: false,
                syncLoading: false,
                self: this,

            }
        },
        created() {
            if(!devices.isRegistered) {
                this.$store.registerModule('devices', devices)
                devices.isRegistered = true
            }
        },

        computed: {
            // alarms() {
            //     return this.$store.getters['devices/getAlarmsByPackageId'](this.package_id)
            // },
            // unused_alarms() {
            //     return this.$store.getters["devices/unusedAlarms"]
            // },
            //
            // filteredDataObj() {
            //     return this.unused_alarms.filter((option) => {
            //         return option.name
            //             .toString()
            //             .toLowerCase()
            //             .indexOf(this.alarmname.toLowerCase()) >= 0
            //     })
            // }
        },
        mounted() {
            // this.$store.dispatch('devices/fetchAlarms', 'include=package,riscorunner.nas')
        },
        methods: {
            psReady (ps) {
                this.ps = ps
            },
            detachModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Detach Alarm',
                    message: 'Are you sure you want to <b>detach</b> this alarm? Existing Alarms and Alerts wont be visible anymore.',
                    confirmText: 'Detach Alarm',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => this.detachAlarm(id)
                })
            },
            attachAlarm(alarm_id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: SelectNasComponent,
                    props: {
                        alarm_id: alarm_id,
                        package_id: this.package_id
                    },
                    hasModalCard: false,
                    trapFocus: true
                })
            },
            detachAlarm(id) {
                this.$store.dispatch('devices/updateAlarm', {id: id, package_id: null, nas_id: null } )
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Alarm attached',
                            type: 'is-info',
                            queue: false
                        })
                    })
            },
            newAlarm() {
                this.$buefy.modal.open({
                    parent: this,
                    component: AlarmFormComponent,
                    props: {
                        id: null
                    },
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
            },
            submitNewAlarm(value) {
                this.$store.dispatch('devices/createAlarm', value)
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Alarm created',
                            type: 'is-success'
                        })
                        this.$refs.autocomplete.setSelected(response.data.data.id)

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                })
            },
            formModal(id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: AlarmFormComponent,
                    props: {
                        id: id
                    },
                    events: {
                      update: () =>  {
                        this.$emit('update')
                      }
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            disconnect(id) {
                this.disconnectLoading = true

                this.$store.dispatch('devices/disconnectAlarm', id)
                    .then(response => {
                      this.$buefy.snackbar.open({
                        message: 'Alarm disconnected. No updates will be received by portal.',
                        type: 'is-success',
                        position: 'is-top',
                        actionText: 'OK',
                        indefinite: true,
                      })
                        this.disconnectLoading = false

                    }).catch(err => {
                    this.$buefy.snackbar.open({
                      message: err,
                      type: 'is-danger',
                      position: 'is-top',
                      actionText: 'OK',
                      indefinite: true,
                    })


                    this.disconnectLoading = false

                })
            },
            connect(id) {
                this.connectLoading = true
                this.$store.dispatch('devices/connectAlarm', id)
                    .then(response => {
                      this.$buefy.snackbar.open({
                        message: 'Alarm reconnected. Panel will now start syncing, be patient!',
                        type: 'is-success',
                        position: 'is-top',
                        actionText: 'OK',
                        indefinite: true,
                      })
                        this.connectLoading = false

                    }).catch(err => {
                      this.$buefy.snackbar.open({
                        message: err,
                        type: 'is-danger',
                        position: 'is-top',
                        actionText: 'OK',
                        indefinite: true,
                      })
                    this.connectLoading = false

                })
            },
            reboot(id) {
              this.rebootLoading = true
              this.$store.dispatch('devices/rebootAlarm', id)
                  .then(response => {
                    this.$buefy.snackbar.open({
                      message: 'Worker rebooted. Panel will now start syncing, be patient!',
                      type: 'is-success',
                      position: 'is-top',
                      actionText: 'OK',
                      indefinite: true,
                    })
                    this.rebootLoading = false

                  }).catch(err => {
                this.$buefy.snackbar.open({
                  message: err,
                  type: 'is-danger',
                  position: 'is-top',
                  actionText: 'OK',
                  indefinite: true,
                })
                this.rebootLoading = false

              })
            },
            sync(id) {
                this.syncLoading = true
                this.$store.dispatch('devices/syncAlarm', id)
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Alarm synchronized',
                            type: 'is-success'
                        })
                        this.syncLoading = false

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                    this.syncLoading = false

                })
            },
        }
    }
</script>

<style lang="scss">

</style>
