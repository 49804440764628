<template>
  <section class="section is-main-section">

    <title-bar :title-stack="titleStack"/>

    <b-tabs v-model="activeTab">
      <b-tab-item label="Role Presets">
        <preset-table></preset-table>
      </b-tab-item>

      <b-tab-item label="Client Roles">
        <role-permission-table :te="0">

        </role-permission-table>
      </b-tab-item>

      <b-tab-item label="TE Roles">
        <role-permission-table :te="1">

        </role-permission-table>
      </b-tab-item>

    </b-tabs>

  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "@/components/TitleBar";
import RolePermissionTable from "../components/roles/RolePermissionTable";
import PresetTable from "@/components/roles/PresetTable";

export default {
  name: 'Settings',
  components: {PresetTable, RolePermissionTable, TitleBar, CardComponent},
  data() {
    return {
      activeTab: 0
    }
  },
  computed: {
    titleStack() {
      return [
        'Admin',
        'Settings',
      ]
    }
  }
}
</script>

<style >


</style>
