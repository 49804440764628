<template>
    <card-component :title="this.creating ? 'Create AIBox': 'Edit AIBox'"
                    :icon="this.creating ? 'plus-circle' : 'pencil'"
                    :is-scrollable="true">

        <form @submit.prevent="submit">
            <b-field label="Name" horizontal>
                <b-input v-model="aibox.name" placeholder="AIBOX4CH" required />
            </b-field>
            <b-field label="IP & port" horizontal>
                <b-field message="Note: local ip and port. You might need to use these values while registering the worker.">

                    <b-input v-model="aibox.ip" expanded placeholder="192.168.64..." ></b-input>
                    <p class="control">
                        <span class="button is-static">:</span>
                    </p>
                    <b-input type="number"  v-model="aibox.port" required></b-input>
                </b-field>
            </b-field>

            <b-field label="Login" horizontal>
              <b-field>
                <b-input v-model="aibox.username" placeholder="admin" name="username" required />
              </b-field>
              <b-field>
                <b-input v-model="aibox.password" type="password" password-reveal name="username" required />
              </b-field>
            </b-field>

            <template v-if="aibox.worker">
              <hr>
              <b-field label="Runner port" horizontal>
                <b-field  message="There is a Runner linked to this aibox. You can change the external port of the Runner here.">
                  <b-numberinput v-model="aibox.worker.port" name="runner_port" required />
                </b-field>
              </b-field>
              <b-field label="Runner version" horizontal>
                <b-field  message="Version of latest runner who operated this aibox">
                  {{ aibox.worker.worker_version | moment("DD-MM-YYYY HH:mm:ss") }}
                </b-field>
              </b-field>
              <b-field label="Linked at" horizontal>
                <b-field  message="Timestamp when the runner made first contact with the portal.">
                  {{ aibox.worker.linked_at | moment("DD-MM-YYYY HH:mm:ss") }}
                </b-field>
              </b-field>
              <hr>
            </template>

            <b-field horizontal >
                <b-field grouped position="is-right">
                    <div class="control">
                        <b-button type="is-primary is-outlined" @click="this.$parent.close">Cancel</b-button>
                    </div>
                    <div class="control">
                        <b-button native-type="submit" type="is-primary">{{ this.creating ? "Create" : "Update" }}</b-button>
                    </div>
                </b-field>
            </b-field>

        </form>

    </card-component>
</template>

<script>
    import devices from "@/store/modules/devices.store"
    import CardComponent from "@/components/CardComponent";

    export default {
        name: "AIBoxFormComponent",
        components: { CardComponent },
        props: {
            id: {
                default: null
            },
        },
        data() {
            return {
                aibox: {},
            }
        },

        computed: {
            creating () {
                return this.id === null
            }

        },
        created() {
            if(!devices.isRegistered) {
                this.$store.registerModule('devices', devices)
                devices.isRegistered = true
            }
        },

        mounted() {
            if (this.id === null) {
                this.aibox = {
                    name: "",
                    ip: "",
                    port: 8443,

                }
            }
            else {
                // Fetch alarm data
                this.$store.dispatch('devices/fetchAIBoxDetails', this.id)
                    .then(response => {
                        this.aibox = response.data.data
                    })
            }
        },

        methods: {


            submit() {
                // Add new alarm
                if (this.creating) {
                    this.$store.dispatch('devices/createAIBox', this.aibox)
                        .then(response => {
                            this.$parent.close()
                            this.$buefy.toast.open({
                                message: 'AIBox created',
                                type: 'is-success'
                            })

                        }).catch(err => {
                        this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                        })
                }
                // Update existing alarm
                else {
                    this.$store.dispatch('devices/updateAIBox', this.aibox)
                        .then(response => {
                          this.$emit("update")
                          this.$parent.close()
                          this.$buefy.toast.open({
                                message: 'AIBox updated',
                                type: 'is-success'
                            })

                        }).catch(err => {
                        this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                    })
                }

            }
        }

    }
</script>

<style scoped lang="scss">


</style>
