<template>
    <card-component class="has-table has-mobile-sort-spaced" :has-button-slot="true" title="Camera" icon="server">
        <div slot="button" class="buttons">
            <b-button @click="formModal(null)" type="is-grey" size="is-small" icon-left="plus-circle">
                Add
            </b-button>
            <refresh-button slot="button" :isLoading="loading" @button-click="fetchData"/>
        </div>
        <b-table
                :data="data"
                :loading="loading"
                :checkable="false"
                :hoverable="true"

                paginated
                backend-pagination
                :total="total"
                :per-page="per_page"
                @page-change="onPageChange"

                backend-sorting
                :default-sort="[sort_field, sort_order]"
                @sort="onSort">

            <b-table-column field="id" label="id" width="40" sortable v-slot="props">
                {{ props.row.id }}
            </b-table-column>
            <b-table-column field="name" label="name"  sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="port" label="port" width="80" sortable v-slot="props">
                {{ props.row.port }}
            </b-table-column>
            <b-table-column field="type" label="type" width="120" sortable v-slot="props">
                <b-tag rounded size="small">{{ props.row.type }}</b-tag>
            </b-table-column>
            <b-table-column style="cursor: pointer" field="nas" label="nas" width="100" v-slot="props">
                <b-tag v-if="props.row.nas" type="is-info is-light" size="small">{{ props.row.nas.ip }}</b-tag>
            </b-table-column>
            <b-table-column style="cursor: pointer" field="package" label="package" v-slot="props">
                <template>
                    <b-tag v-if="props.row.package" style="cursor: pointer" type="is-info is-light" size="small" @click.native="$router.push({ name:'package.detail', params: {id: props.row.package.id}})">
                        {{ props.row.package.name }}
                    </b-tag>
                </template>

            </b-table-column>

            <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
                <div class="buttons is-right">
                    <b-button icon-left="pencil" size="is-small" type="is-success" @click="formModal(props.row.id)"></b-button>

                    <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
                        <b-button icon-left="menu" size="is-small" type="is-primary" slot="trigger" slot-scope="{ active }">
                        </b-button>

                        <b-dropdown-item aria-role="listitem">
                            <a class="media" :href="'http://' + props.row.ip + ':' + props.row.port" target="_blank" >
                                <b-icon class="media-left" icon="arrow-right"></b-icon>
                                <div class="media-content">
                                    <h3>Access</h3>
                                </div>
                            </a>
                        </b-dropdown-item>

                        <b-dropdown-item aria-role="listitem" >
                            <div class="media" @click="deleteModal(props.row.id)">
                                <b-icon class="media-left" icon="delete"></b-icon>
                                <div class="media-content">
                                    <h3>Remove</h3>
                                </div>
                            </div>
                        </b-dropdown-item>

                    </b-dropdown>

                </div>
            </b-table-column>

            <template slot="footer">


            </template>



        </b-table>
    </card-component>
</template>

<script>
    import devices from "@/store/modules/devices.store"
    import CardComponent from "@/components/CardComponent";
    import CameraFormComponent from "./CameraFormComponent";
    import RefreshButton from '@/components/RefreshButton'

    export default {
        name: "CameraTableComponent",
        components: { CardComponent, RefreshButton },

        data () {
            return {
                data: [],
                loading: true,
                total: 0,
                page: 1,
                per_page: 10,
                sort_field: "",
                sort_order: "asc",
            }
        },

        created() {
            if(!devices.isRegistered) {
                this.$store.registerModule('devices', devices)
                devices.isRegistered = true
            }
        },

        mounted() {
            this.fetchData()
        },

        methods: {

            fetchData() {
                this.loading = true

                const params = [
                    'include=nas,package.project',
                    `page=${this.page}`,
                    `per_page=${this.per_page}`,
                    `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,

                ].join('&')

                this.$store.dispatch('devices/fetchCameras', params)
                    .then(response => {
                        this.data = response.data.data
                        this.total = response.data.meta.total

                        this.loading = false
                    })
            },
            onPageChange(page) {
                this.page = page
                this.fetchData()
            },
            /*
            * Handle sort event
            */
            onSort(field, order) {
                this.sort_field = field
                this.sort_order = order
                this.fetchData()
            },

            deleteModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Removing Camera',
                    message: 'Are you sure you want to <b>remove</b> this Camera? This action cannot be undone.',
                    confirmText: 'Remove Camera',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('devices/deleteCamera', id)
                            .then(response => {
                                this.$buefy.toast.open('Camera removed!')
                            })
                    }
                })
            },

            formModal(id) {
              let thisIns = this;
              this.$buefy.modal.open({
                    parent: this,
                    component: CameraFormComponent,
                    props: {
                        id: id
                    },
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true,
                    events: {
                      refresh () {
                        thisIns.fetchData();
                      }
                    }
                })
            }

        }




    }
</script>

<style scoped>

</style>
