<template>
    <card-component :title="this.creating ? 'Create Nas': 'Edit Nas'"
                    :icon="this.creating ? 'plus-circle' : 'pencil'"
                    :is-scrollable="true">

        <form v-if="!created" @submit.prevent="submit">
            <b-field label="Name" horizontal>
                <b-field>
                    <b-input v-model="nas.name" name="name" required />
                </b-field>
            </b-field>
            <b-field label="IP & port" horizontal>
                <b-field message="Enter the ip and port where DSM is running">
                    <b-input expanded v-model="nas.ip" placeholder="192.168.64..." ></b-input>
                    <p class="control">
                        <span class="button is-static">:</span>
                    </p>
                    <b-input v-model="nas.port" placeholder="5000" ></b-input>
                </b-field>
            </b-field>

            <b-field label="DDNS" horizontal>
                <b-field message="This domain will also be used for accessing the linked cameras and alarms.">
                    <p class="control">
                        <span class="button is-static">http://</span>
                    </p>
                    <b-input expanded v-model="nas.synodns" placeholder="towereye002.synology.me" ></b-input>
                    <p class="control">
                        <span class="button is-static">:</span>
                    </p>
                    <b-input expanded v-model="nas.port" placeholder="5000" ></b-input>
                    <p class="control">
                      <span class="button is-static">rtsp</span>
                    </p>
                    <b-input expanded v-model="nas.rtsp_port" placeholder="5554" required></b-input>
                </b-field>
            </b-field>
            <b-field label="Login" horizontal>
                <b-field>
                    <b-input v-model="nas.username" placeholder="admin" name="username" required />
                </b-field>
                <b-field>
                    <b-input v-model="nas.password" type="password" password-reveal name="username" required />
                </b-field>
            </b-field>
            <b-field label="Type" horizontal>
                <b-field>
                    <b-select v-model="nas.type" placeholder="Select a type">
                        <option value="DS216play">DS216play</option>
                        <option value="DS216+">DS216+</option>
                        <option value="DS218+">DS218+</option>
                        <option value="DS218play">DS218play</option>
                    </b-select>
                </b-field>
            </b-field>
            <b-field label="Mac" horizontal>
                <b-field message="If you're planning to attach a Worker, make sure to enter the same address on both.">
                    <b-input v-model="nas.mac" name="mac" required />
                </b-field>
            </b-field>
            <b-field label="Gateway" horizontal>
            <b-field>
                <b-input v-model="nas.gateway" name="gateway" required />
            </b-field>
        </b-field>
            <b-field label="Subnet" horizontal>
                <b-field>
                    <b-input v-model="nas.subnetmask" name="subnetmask" required />
                </b-field>
            </b-field>

            <template v-if="!this.creating">
                <hr>

                <b-field label="Rekey" horizontal>
                    <b-field message="This will reset the authorization key for the RiscoRunner. The previous key will be overwritten.">
                        <b-button @click="rekeyModal" size="is-small" type="is-danger" outlined icon-left="refresh">Recreate key</b-button>
                    </b-field>
                </b-field>
            </template>

            <hr>


            <b-field horizontal >
                <b-field grouped position="is-right">
                    <div class="control">
                        <b-button type="is-primary is-outlined" @click="this.$parent.close">Cancel</b-button>
                    </div>
                    <div class="control">
                        <b-button native-type="submit" type="is-primary">{{ this.creating ? "Create" : "Update" }}</b-button>
                    </div>
                </b-field>

            </b-field>

        </form>

        <form v-if="created">
            <b-notification type="is-info"> Token generated! This is the access token that needs to be added to the runner config. This token will be hashed, so make sure to keep it safe! <b> It cannot be viewed again. </b></b-notification>

            <b-field expanded label="Access Token" horizontal>
                <b-input v-model="this.token"
                         expanded
                         icon="key">
                </b-input>
                <p class="control">
                    <b-button v-clipboard="this.token" @success="copySuccess"
                              icon-right="clipboard-multiple-outline" type="is-info is-light"/>
                </p>
            </b-field>





        </form>
    </card-component>
</template>

<script>
    import devices from "@/store/modules/devices.store"
    import CardComponent from "@/components/CardComponent";




    export default {
        name: "NasFormComponent",
        components: { CardComponent },
        props: {
            id: {
                default: null
            },
            package_id: {
                default: null
            },
        },
        data() {
            return {
                nas: {},
                // used for displaying token after response
                created: false,
                token: "",

            }
        },

        computed: {
            creating () {
                return this.id === null
            }

        },
        created() {
            if(!devices.isRegistered) {
                this.$store.registerModule('devices', devices)
                devices.isRegistered = true
            }
        },

        mounted() {
            if (this.id === null) {
                this.nas = {
                    name: "",
                    ip: "",
                    port: 5000,
                    synodns: "",
                    username: "",
                    password: "",
                    type: "DS218play",
                    mac: "",
                    gateway: "192.168.64.1",
                    subnetmask: "255.255.248.0",
                    package_id: this.package_id
                }
            }
            else {
                // Fetch nas data
                this.$store.dispatch('devices/fetchNasDetails', this.id)
                    .then(response => {
                        this.nas = JSON.parse(JSON.stringify(response.data.data))
                    })
            }
        },

        methods: {
            copySuccess() {
                this.$buefy.toast.open({
                  message: 'Token copied',
                  queue: false
                })
            },

            submit() {
                // Add new nas
                if (this.creating) {
                    this.$store.dispatch('devices/createNas', this.nas)
                        .then(response => {
                            this.token = response.data.token.plainTextToken
                            this.created = true
                            //this.$parent.close()
                            this.$buefy.toast.open({
                                message: 'Nas created',
                                type: 'is-success'
                            })
                            this.$emit("update")

                        }).catch(err => {
                        this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                        })
                }
                // Update existing nas
                else {
                    this.$store.dispatch('devices/updateNas', this.nas)
                        .then(response => {
                            this.$parent.close()
                            this.$buefy.toast.open({
                                message: 'Nas updated',
                                type: 'is-success'
                            })

                        }).catch(err => {
                        this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                    })
                }

            },

            rekeyModal() {
                this.$buefy.dialog.confirm({
                    title: 'Recreate Token',
                    message: 'This will reset the authorization key for the RiscoRunner. The previous key will be overwritten, so be careful!',
                    confirmText: 'Recreate Token',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.rekey()
                })
            },

            rekey() {

                this.$store.dispatch('devices/rekeyNas', this.nas)
                    .then(response => {
                        this.token = response.data.plainTextToken
                        this.created = true
                        //this.$parent.close()
                        this.$buefy.toast.open({
                            message: 'Nas token recreated',
                            type: 'is-success'
                        })

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                })

            },
        }

    }
</script>

<style scoped lang="scss">


</style>