<template>
    <card-component title="Nas" :is-scrollable="false" :has-button-slot="true" :has-footer-slot="true">
<!--        <refresh-button slot="button" :is-loading="isLoading" @button-click="delayedFetch"/>-->

        <div class="media-list">
            <b-loading :is-full-page="false" :active="isLoading"/>

            <div  class="media" v-for="nas in this.nases">

                <div class="media-content">
                    <div class="content">
                        <p class="media-meta">
                            <nav class="level is-mobile">
                                <div class="level-left">
                                    <strong class="level-item">{{ nas.id }} | {{ nas.name }}</strong>
                                    <b-tag rounded size="small">{{ nas.type }}</b-tag>
                                </div>
                                <div class="level-right">
                                    <b-button  size="is-small" type="is-grey" icon-left="link-variant-off" class="level-item" @click="detachModal(nas.id)">

                                    </b-button>
                                </div>
                            </nav>
                        </p>


                    </div>
                    <nav class="level is-mobile">
                        <div class="level-left">
                            <a :href="nas.synodns + ':' + nas.port" class="level-item text is-size-6">
                                {{ nas.synodns }}
                            </a>
                        </div>
                        <div class="level-right">
                            <b-button  size="is-small" type="is-success" icon-left="pencil" class="level-item" @click="formModal(nas.id)">

                            </b-button>
                        </div>

                    </nav>
                </div>
            </div>
            <div v-if="this.nases.length === 0">
                <p class="has-text-centered">
                    <b-icon icon="cactus" size="is-large" />
                </p>
                <p class="has-text-centered">No linked nas's found</p>
            </div>
        </div>

        <div class="card-footer-item card-footer" slot="footer" >
            <b-field expanded style="width: 100%">
                <b-autocomplete
                        v-model="nasname"
                        expanded
                        append-to-body
                        placeholder="Attach nas..."
                        ref="autocomplete"
                        :open-on-focus="true"
                        :data="filteredDataObj"
                        icon="plus-circle"
                        field="name"
                        @select="option => nas_id = option.id">
                    <template #footer>
                        <a @click="newNas">
                            <span> Add new... </span>
                        </a>
                    </template>
                  <template #empty>No results for {{nasname}}</template>
                </b-autocomplete>
                <p class="control">
                    <b-button :disabled="nas_id === ''" icon-left="link" class="button is-primary"
                              @click="atttachNas(nas_id)"/>
                </p>
            </b-field>


        </div>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/CardComponent'
    import CardToolbar from '@/components/CardToolbar'
    import NasFormComponent from "./NasFormComponent";
    import devices from "@/store/modules/devices.store"


    export default {
        name: 'NasListComponent',
        components: { CardToolbar, CardComponent },
        props: ['package_id', 'nases'],
        data () {
            return {
                isLoading: false,
                nas_id: 0,
                nasname: "",
            }
        },
        created() {
            if(!devices.isRegistered) {
                this.$store.registerModule('devices', devices)
                devices.isRegistered = true
            }
        },

        computed: {

            unused_nas() {
                return this.$store.getters["devices/unusedNas"]
            },

            filteredDataObj() {
                return this.unused_nas.filter((option) => {
                    return option.name
                        .toString()
                        .indexOf(this.nasname) >= 0
                })
            }
        },
        mounted() {
            this.$store.dispatch('devices/fetchUnusedNas', 'unused=1')
        },
        methods: {

            detachModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Detach Nas',
                    message: 'Are you sure you want to <b>detach</b> this nas? Existing Alarms and Alerts wont be visible anymore.',
                    confirmText: 'Detach Nas',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => this.detachNas(id)
                })
            },
            atttachNas() {
                this.$store.dispatch('devices/updateNas', {id: this.nas_id, package_id: this.package_id} )
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Nas attached',
                            type: 'is-success',
                            queue: false
                        })
                        this.$emit("update")
                    })
            },
            detachNas(id) {
                this.$store.dispatch('devices/updateNas', {id: id, package_id: null} )
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Nas detached',
                            type: 'is-success',
                            queue: false
                        })
                      this.$emit("update")
                    })

            },
            formModal(id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: NasFormComponent,
                    props: {
                        id: id
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            newNas() {
                this.$buefy.modal.open({
                    parent: this,
                    component: NasFormComponent,
                    props: {
                        id: null,
                        package_id: this.package_id
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    events: {
                        update: () =>  {
                            this.$emit('update')
                        }
                    }
                })

            },
            submitNewNas(value) {
                this.$store.dispatch('users/createCompany', value)
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Company created',
                            type: 'is-success'
                        })

                        this.$refs.autocomplete.setSelected(response.data)

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                })
            },
        }
    }
</script>
