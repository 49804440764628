<template>
  <div>
    <b-table v-if="roles"
             :data="roles"
             :loading="loading"
             :hoverable="true"
             :mobile-cards="false"
             :key="project_users.map(c => c.user.id).join()"

    >

      <b-table-column field="roles" width="300" sticky>

        <template v-slot:subheading="{ column }">
          Copy from preset
        </template>

        <template v-slot="props">
          <article class="media">
            <div class="media-content">
              <div class="media-meta">
                <p>
                  <strong>{{ props.row.role }}</strong>
                </p>
                <p class="text is-size-7">
                  {{ props.row.description }}
                </p>
              </div>
            </div>
          </article>
        </template>

      </b-table-column>
      <b-table-column v-for="user in project_users"
                      :key="user.id"
                      :field="user.user.name"
                      cell-class="vcenter"
                      :centered="true"
                      :label="user.user.name">

        <template v-slot:header="{ column }">
          <article class="media has-text-left">
            <figure class="media-left" v-if="user.user.email">
              <p class="image is-32x32">
                <user-avatar :email="user.user.email"/>
              </p>
            </figure>
            <div class="media-content">
              <div class=" media-meta">
                <p class=" mb-0">
                  <strong>{{ column.label }}</strong>
                </p>
                <p class="text is-size-7 has-text-weight-light">
                  {{ user.user.email }}
                </p>

              </div>
            </div>
            <div class="media-right ml-0">
              <a @click="confirmRemoveUser(user.user)">
                <b-icon size="is-small" icon="close-circle" ></b-icon>
              </a>
            </div>
          </article>
        </template>

        <template v-slot:subheading="{ column }">
          <b-field>
            <b-select v-model="user.user.preset"
                      @input="setPreset(user.user)"
                      size="is-small"
                      placeholder="Select preset"
            >
              <option
                  v-for="preset in presets"
                  :value="preset"
                  :key="preset.id">
                {{ preset.name }}
              </option>

            </b-select>
          </b-field>
        </template>

        <template v-slot="props">
          <div class="field has-text-centered">
            <b-checkbox :value="user.roles.filter(_role => _role.id === props.row.id).length > 0"
                        @input="(value) => updateRole(value, user.id, user.user.id, props.row.id)">
            </b-checkbox>
          </div>
        </template>
      </b-table-column>


      <div slot="footer">
        <br>
        <b-field>
          <b-autocomplete
              v-model="newusername"
              placeholder="Add user"
              ref="autocomplete"
              dropdown-position="top"
              :open-on-focus="true"
              :data="filteredDataObj"
              icon="plus-circle"
              field="name"
              @select="option => newuserid = option.id">
            <template #footer>
              <a @click="newUser">
                <span> Invite user... </span>
              </a>
            </template>
            <template #empty>No results for {{newusername}}</template>

          </b-autocomplete>
          <p class="control">
            <b-button :disabled="newuserid === ''" icon-left="plus-circle" class="button is-primary"
                      @click="addUser(newuserid)"/>
          </p>
        </b-field>
      </div>

      <div slot="empty">
        <p class="has-text-centered">
          <b-icon icon="cactus" size="is-large"/>
        </p>
        <p class="has-text-centered">Let's start adding users to this project!</p>
      </div>
    </b-table>
  </div>

</template>


<script>
import UserAvatar from "../UserAvatar";
import users from "@/store/modules/users.store"
import NewUserComponent from "@/components/users/NewUserComponent";

export default {
  name: "ProjectPermissionTable",
  props: ["project"],
  components: {UserAvatar},
  data() {
    return {
      project_users: [],
      roles: [],
      presets: [],
      company_users: [],
      loading: true,
      newuserid: null,
      newusername: null,

    }
  },
  computed: {

    filteredDataObj() {
      if (!this.newusername) return this.company_users
      return this.company_users.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.newusername) >= 0
      })
    }

  },
  created() {
    if (!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      this.company_users = []
      this.$store.dispatch('projects/fetchProjectPermissions', {id: this.project.id})
          .then(response => {
            this.project_users = response.data.data
            this.loading = false;

          })
      this.$store.dispatch('users/fetchRoles').then(response => {
        this.roles = response.data.data
      })
      this.$store.dispatch('users/fetchPresets').then(response => {
        this.presets = response.data.data
      })
      this.$store.dispatch('users/fetchCompanyDetails', {
        id: this.project.company.id,
        params: "include=users"
      }).then(response => {
        this.company_users = this.company_users.concat(response.data.users)
        this.loading = false;
      })

      const params = "meldkamer=1"
      this.$store.dispatch('users/fetchUsers', { params })
          .then(response => {
            this.company_users = this.company_users.concat(response.data)
            this.loading = false
          })
    },

    setPreset(user) {
      this.$store.dispatch('projects/updateProjectUserRole', {
        data: {roles: user.preset.roles.map(a => a.id)},
        project_id: this.project.id,
        user_id: user.id
      }).then(response => {

        this.$buefy.toast.open({
          type: "is-success",
          message: "Roles updated",
          queue: false,
        })
        this.fetchData();

      })

    },

    updateRole(value, project_user_id, user_id, role_id) {

      let user_roles = this.project_users.find(packusr => {
        return packusr.id === project_user_id
      })
      let result = user_roles.roles.map(a => a.id);

      if (value) {
        result.push(role_id)
      } else {
        let index = result.findIndex(id => id === role_id)
        result.splice(index, 1)
      }

      this.$store.dispatch('projects/updateProjectUserRole', {
        data: {roles: result},
        project_id: this.project.id,
        user_id: user_id
      }).then(response => {

        this.$buefy.toast.open({
          type: "is-success",
          message: "Roles updated",
          queue: false,
        })
        this.fetchData();

      })


    },

    addUser(user_id) {
      this.$store.dispatch('projects/addUserToProject', {
            project_id: this.project.id,
            data: {user_id: user_id}
          }
      ).then(response => {
        this.$buefy.toast.open({
          type: "is-success",
          message: "User added to project"
        })
        this.fetchData();
      })
    },

    newUser() {
      this.$buefy.modal.open({
        parent: this,
        component: NewUserComponent,
        props: {
          project_id: this.project.id,
          presets: this.presets,
        },
        hasModalCard: true,
        trapFocus: true
      })
    },

    confirmRemoveUser(user) {
      let thisIns = this;
      this.$buefy.dialog.confirm({
        title: 'Remove user from Project',
        message: `Are you sure you want to <b>remove</b> ${user.name} from this project? The user won't have access anymore.`,
        confirmText: 'Remove from project',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('projects/removeUserFromProject', {
                project_id: this.project.id,
                user_id: user.id
              }
          ).then(response => {
            this.$buefy.toast.open({
              type: "is-success",
              message: "User removed from project"
            })
            thisIns.fetchData();
          })
        }
      })
    },

  }
}
</script>

<style lang="scss">

.b-table {
  .table {
    td.vcenter {
      vertical-align: middle;
    }
  }
}


</style>
