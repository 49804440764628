<template>
    <section class="section is-main-section">

        <title-bar :title-stack="titleStack"/>

        <card-component class="has-table has-mobile-sort-spaced">
          <nas-table-component>

          </nas-table-component>
      </card-component>
    </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import NasTableComponent from "@/components/devices/nas/NasTableComponent";
import TitleBar from "@/components/TitleBar";

export default {
  name: 'DeviceList',
  components: { TitleBar, NasTableComponent, CardComponent },
  computed: {
    titleStack () {
      return [
          'Admin',
          'Devices',
          'Nas'
      ]
    }
  }
}
</script>
