<template>
  <card-component title="Invite User" icon="account-circle" style="min-width: 40vw">
    <b-loading :is-full-page="false" :active="isLoading"/>

    <form @submit.prevent="submit">

      <b-field label="Email"
               :type="{ 'is-danger': errors.email }"
               :message="errors.email"
               horizontal>

        <b-input v-model="user.email"
                 placeholder="name@company.be"
                 type="email"
                 required/>

      </b-field>

      <b-field v-if="this.companies"
               label="Company"
               :type="{ 'is-danger': errors.company }"
               :message="errors.company"
               horizontal>
        <b-autocomplete
            v-model="companyname"
            placeholder="e.g. MyCompany"
            ref="autocomplete"
            :open-on-focus="true"
            :data="filteredDataObj"
            field="name"
            required
            @select="option => user.company_id = option.id">
          <template slot="header">
            <a v-can="'manage all companies'" @click="newCompany">
              <span> Add new... </span>
            </a>
          </template>
          <template #empty>No results for {{companyname}}</template>
        </b-autocomplete>
      </b-field>

      <b-field v-if="presets"
               label="Permissions preset"
               horizontal
               message="User will be automatically added to this project using this preset once the invite is accepted."
               expanded>
        <b-select v-model="user.preset_id"
                  expanded
                  placeholder="Select preset"
        >
          <option
              v-for="preset in presets"
              :value="preset.id"
              :key="preset.id">
            {{ preset.name }}
          </option>

        </b-select>
      </b-field>

      <hr>

      <b-field horizontal>
        <b-field grouped position="is-right">
          <div class="control">
            <b-button native-type="submit" type="is-primary">Send</b-button>
          </div>
        </b-field>
      </b-field>

    </form>
  </card-component>
</template>

<script>
import projects from "@/store/modules/projects.store"
import CardComponent from "../CardComponent";
import users from "@/store/modules/users.store"


export default {
  name: "NewUserComponent",
  components: {CardComponent},
  props: {
    project_id: {
      default: null
    },
    presets: {
      default: null
    }
  },

  data() {
    return {
      user: {
        email: "",
        company_id: 0,
        project_id: this.project_id
      },
      companyname: "",
      isLoading: false,
      errors: {},

    }
  },
  created() {
    if (!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }

  },
  mounted() {
    this.$store.dispatch('users/fetchCompanies')
        .then(response => {
          // If only 1 company, auto select it
          if (response.data.length === 1) {
            this.user.company_id = response.data[0].id
            this.companyname = response.data[0].name

          }
        })
  },

  computed: {
    creating() {
      return this.id === null
    },
    companies() {
      return this.$store.state.users.companies
    },

    filteredDataObj() {
      return this.companies.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.companyname.toLowerCase()) >= 0
      })
    }
  },

  methods: {


    submit() {
      this.isLoading = true
      this.$store.dispatch('users/inviteUser', this.user)
          .then(response => {
            this.isLoading = false
            this.$parent.close()
            this.$buefy.toast.open({
              message: 'User invited',
              type: 'is-success'
            })


          }).catch(err => {
        this.isLoading = false
        this.errors = err.response.data
      })
    },

    newCompany() {
      this.$buefy.dialog.prompt({
        message: `What's the company name?`,
        inputAttrs: {
          placeholder: 'e.g. MyCompany',
          maxlength: 50
        },
        trapFocus: true,
        onConfirm: (value) => this.submitNewCompany({name: value})
      })

    },
    submitNewCompany(value) {
      this.$store.dispatch('users/createCompany', value)
          .then(response => {
            this.$buefy.toast.open({
              message: 'Company created',
              type: 'is-success'
            })

            this.$refs.autocomplete.setSelected(response.data)

          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
      })
    },
  }

}
</script>

<style scoped>

</style>