<template>
  <card-component class=" has-mobile-sort-spaced" :has-button-slot="true" title="Permissions per role" icon="lock">
    <b-message v-if="te" type="is-danger" has-icon>
      With great power comes great responsibility! Some of these settings are not supposed to be changed
      and can result in a system malfunction. Removing TE roles is disabled.
    </b-message>

    <b-message v-else type="is-info is-light">Permissions itself are defined in the backend, and will be checked on
      every action.
      Features can be assigned to Users on a Project, and can be grouped in a preset to easily assign them to a user.
    </b-message>
    <div v-if="!te" slot="button" class="buttons">
      <b-button @click="createModal(null)" type="is-grey" size="is-small" icon-left="plus-circle">
        Add
      </b-button>
    </div>

    <b-table v-if="roles"
             :data="permissions"
             :loading="loading"
             :hoverable="true"
             :key="roles.map(r => r.id).join()"
             sticky-header
             height="55vh"
    >
      <b-table-column field="permission"
                      sticky
                      v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-for="role in roles"
                      :key="role.id"
                      :field="role.role"
                      :label="role.role"
                      centered
                      cell-class="vcenter"
      >

        <template v-slot:header="{ column }">
          <article class="media has-text-left">
            <div class="media-content">
              <div class=" media-meta">
                <p class=" mb-0">
                  <strong>{{ role.role }}</strong>
                </p>
              </div>
            </div>
            <div v-if="!te" class="media-right ml-0">
              <a @click="confirmRemoveRole(role)">
                <b-icon size="is-small" icon="close-circle"></b-icon>
              </a>
            </div>
          </article>
        </template>

        <template v-slot="props">
          <b-field class="has-text-centered">
            <b-checkbox :value="role.permissions.filter(_role => _role.id === props.row.id).length > 0"
                        @input="(value) => updateRole(value, role, props.row)">
            </b-checkbox>
          </b-field>
        </template>
      </b-table-column>

      <div class="has-text-right" slot="footer">
        <br>
        <b-field>

        </b-field>
      </div>

    </b-table>
  </card-component>
</template>

<script>
import UserAvatar from "../UserAvatar";
import users from "@/store/modules/users.store"
import CardComponent from "../CardComponent";

export default {
  name: "RolePermissionTable",
  props: {
    te: {
      default: 0,
    }

  },
  components: {CardComponent, UserAvatar},
  data() {
    return {
      permissions: [],
      roles: [],
      loading: true,
    }
  },
  computed: {},
  created() {
    if (!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const params = `?te=${this.te}`

      this.$store.dispatch('users/fetchRoles', params)
          .then(response => {
            this.roles = response.data.data
            this.loading = false;

          })
      this.$store.dispatch('users/fetchPermissions', params).then(response => {
        this.permissions = response.data.data
      })
    },

    updateRole(value, role, permission) {

      if (value) {
        role.permissions.push(permission)
      } else {
        let index = role.permissions.findIndex(perm => perm.id === permission.id)
        role.permissions.splice(index, 1)
      }

      this.$store.dispatch('users/updateRolePermissions', {
        data: {permissions: role.permissions.map(a => a.name)},
        role_id: role.id
      }).then(response => {

        this.$buefy.toast.open({
          type: "is-success",
          message: "Role updated",
          queue: false,
        })
        this.fetchData();

      })


    },

    createModal() {
      this.$buefy.dialog.prompt({
        message: `What's the role name?`,
        inputAttrs: {
          placeholder: 'e.g. Custom Role',
          maxlength: 50
        },
        trapFocus: true,
        onConfirm: (value) => this.submit(value)
      })
    },
    submit(value) {
      this.$store.dispatch('users/createRole', value)
          .then(response => {
            this.$buefy.toast.open({
              message: 'Role created',
              type: 'is-success'
            })
            this.fetchData();


          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
      })
    },
    confirmRemoveRole(role) {
      let thisIns = this;
      this.$buefy.dialog.confirm({
        title: 'Remove role',
        message: `Are you sure you want to <b>remove</b> role ${role.role}? This will affect permissions on existing users.`,
        confirmText: 'Remove role',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('users/removeRole', {
                role_id: role.id
              }
          ).then(response => {
            this.$buefy.toast.open({
              type: "is-success",
              message: "Role removed."
            })
            thisIns.fetchData();
          }).catch(err => {
            this.$buefy.toast.open({
              type: "is-danger",
              message: err.response.data.message
            })
          })
        }
      })
    },

  }
}
</script>

<style lang="scss">

.b-table {
  .table {
    td.vcenter {
      vertical-align: middle;
    }
  }
}
</style>