<template>
  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>
    <card-component icon="history" title="Recent logs" class="has-table has-mobile-sort-spaced">
      <card-toolbar slot="toolbar" class="is-upper">
        <b-field grouped slot="left">
          <b-select v-model="selectedLog" placeholder="Filter types">
            <option value="all">
              All
            </option>
            <option value="viewable">
              Public
            </option>
            <option value="admin">
              Admin
            </option>
          </b-select>
          <b-field>
            <b-button icon-left="filter" type="is-primary" @click="fetchData">Apply</b-button>
          </b-field>
        </b-field>

        <form slot="right">
          <b-field>
            <b-select @input="fetchData" v-model="per_page">
              <option :value="25">
                25 per page
              </option>
              <option :value="50">
                50 per page
              </option>
              <option :value="100">
                100 per page
              </option>
            </b-select>
          </b-field>
        </form>
      </card-toolbar>

      <b-table
          :data="data"
          :loading="loading"
          :checkable="false"
          :hoverable="true"

          paginated
          backend-pagination
          :total="total"
          :per-page="per_page"
          @page-change="onPageChange"

          backend-sorting
          :default-sort="[sort_field, sort_order]"
          @sort="onSort">

        <b-table-column field="project" label="project" v-slot="props">
          <template v-if="props.row.project">
            <b-tag style="cursor: pointer" type="is-light" size="small"
                   @click.native="$router.push({ name:'project.detail', params: {id: props.row.project.id }})">
              {{ props.row.project.name }}
            </b-tag>
          </template>
        </b-table-column>

        <b-table-column field="package" label="package" v-slot="props">
          <template v-if="props.row.properties.p_id">
            <b-tag style="cursor: pointer" type="is-info is-light" size="small"
                   @click.native="$router.push({ name:'package.detail', params: {id: props.row.properties.p_id, project_id: props.row.project.id}})">
              {{ props.row.properties.p_name }}
            </b-tag>
          </template>
        </b-table-column>

        <b-table-column field="icon" class="has-text-centered" width="20" v-slot="props">
          <b-icon
              :icon="props.row.properties.icon"
              :type="props.row.properties.type"
              size="is-small">
          </b-icon>
        </b-table-column>
        <b-table-column field="description" label="description" v-slot="props">
          {{ props.row.description }}
        </b-table-column>

        <b-table-column field="causer_id" label="User" sortable v-slot="props">
          <template v-if="props.row.user">
                <span>
                     <b-icon
                         icon="account"
                         type="grey-dark"
                         size="is-small">
                </b-icon>
                {{ props.row.user.name }}
                </span>
          </template>
        </b-table-column>
        <b-table-column field="created_at" label="Happened" sortable v-slot="props">
          <span>{{ props.row.created_at | moment("from", "now") }}</span> |
          <span class="has-text-grey"><small>{{ props.row.created_at | moment("DD MMM 'YY HH:mm:ss ") }}</small></span>
        </b-table-column>

      </b-table>
    </card-component>
  </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "../components/TitleBar";
import packages from "@/store/modules/packages.store";
import CardToolbar from "@/components/CardToolbar";

export default {
  name: 'Logs',
  components: {CardToolbar, TitleBar, CardComponent},
  data() {
    return {
      data: [],
      loading: true,
      selectedLog: null,
      total: 0,
      page: 1,
      per_page: 50,
      sort_field: "",
      sort_order: "asc",
    }
  },

  created() {
    if (!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
  },
  computed: {
    titleStack() {
      return [
        'Admin',
        'All Logs'
      ]
    },

  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.loading = true

      const params = [
        `filter[log_name]=${this.selectedLog ?? ''}`,
        `page=${this.page}`,
        `per_page=${this.per_page}`,
        `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,

      ].join('&')

      this.$store.dispatch('packages/fetchAllLogs', {params: params})
          .then(response => {
            this.data = response.data.data
            this.total = response.data.meta.total

            this.loading = false
          })
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.sort_field = field
      this.sort_order = order
      this.fetchData()
    },


  }

}
</script>
