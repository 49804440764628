<template>
    <section class="section is-main-section">
        <TitleBar :title-stack="titleStack"/>

        <users-table-component>

        </users-table-component>
    </section>

</template>

<script>
import UsersTableComponent from "@/components/users/UsersTableComponent";
import TitleBar from "../../components/TitleBar";

export default {
  name: 'Users',
  components: {TitleBar, UsersTableComponent },
    computed: {
        titleStack () {
            return [
                'Users'
            ]
        },

    },
  methods: {



  }
}
</script>
