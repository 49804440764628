<template>
        <b-table
                :data="users"
                :loading="loading"
                :checkable="false"
                :striped="true"
                :hoverable="true"
                searchable>

            <b-table-column class="has-no-head-mobile is-image-cell" v-slot="props">
                <div class="image">
                    <user-avatar :email="props.row.email"/>
                </div>
            </b-table-column>
            <b-table-column field="name" label="Name" v-slot="props">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column  field="email" label="Email" v-slot="props">
                <a :href="`mailto:${props.row.email}`" >{{ props.row.email }}</a>
            </b-table-column>
            <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
                <div class="buttons is-right">

                    <b-button icon-left="eye" size="is-small" type="is-success" @click="$router.push({name: 'users.detail', params: { id: props.row.id}})"></b-button>

                </div>
            </b-table-column>
        </b-table>

</template>

<script>
    import UserAvatar from "../UserAvatar";
    import CardComponent from "../CardComponent";


    export default {
        name: "CompanyUsers",
        components: {CardComponent, UserAvatar, },

        props: {
            users: {
                type: Array,
                default: () => {}
            },
        },

        data () {
            return {

            }
        },

        methods: {


        }



    }
</script>

<style scoped>

</style>