<template>
  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>
    <project-table-component/>
  </section>

</template>

<script>
import ProjectTableComponent from "../components/project/ProjectTableComponent";
import TitleBar from "../components/TitleBar";
const GeoJSON = require('geojson');

export default {
  name: 'Projects',
  components: {TitleBar, ProjectTableComponent },
  computed: {
    titleStack () {
      return [
        'Projects'
      ]
    },

  },

}
</script>

<style lang="scss">


</style>