<template>
    <section class="section is-main-section">
        <title-bar :title-stack="titleStack"/>
      <card-component icon="alert" title="Recent Alerts" class="has-table has-mobile-sort-spaced">


        <card-toolbar slot="toolbar" class="is-upper" >
          <b-field grouped slot="left">
              <b-select v-model="selectedStatusInput" placeholder="Filter status">
                <option value="all">
                  All
                </option>
                <option value="pending">
                  Pending
                </option>
                <option value="irrelevant">
                  Irrelevant
                </option>
                <option value="relevant">
                  Relevant
                </option>
              </b-select>

              <b-datetimepicker
                  class="width"
                  icon="calendar-today"
                  placeholder="From..."
                  locale="nl-BE"
                  :mobile-native="true"
                  v-model="afterInput"
              >
                <template #left>
                  <b-button
                      label="Now"
                      type="is-primary"
                      icon-left="clock"
                      @click="afterInput = new Date()"
                  />
                </template>

                <template #right>
                  <b-button
                      label="Clear"
                      type="is-danger"
                      icon-left="close"
                      outlined
                      @click="afterInput = null"
                  />
                </template>
              </b-datetimepicker>

            <b-datetimepicker
                class="width"
                icon="calendar-today"
                placeholder="Until..."
                locale="nl-BE"
                :mobile-native="true"
                v-model="beforeInput"
            >
              <template #left>
                <b-button
                    label="Now"
                    type="is-primary"
                    icon-left="clock"
                    @click="beforeInput = new Date()"
                />
              </template>

              <template #right>
                <b-button
                    label="Clear"
                    type="is-danger"
                    icon-left="close"
                    outlined
                    @click="beforeInput = null"
                />
              </template>
            </b-datetimepicker>
            <b-field>
              <b-button icon-left="filter" type="is-primary" @click="fetchData">Apply</b-button>
            </b-field>
          </b-field>
          <form slot="right" >
            <b-field>
              <b-select @input="fetchData" v-model="per_page">
                <option :value="25">
                  25 per page
                </option>
                <option :value="50">
                  50 per page
                </option>
                <option :value="100">
                  100 per page
                </option>
              </b-select>
            </b-field>
          </form>
        </card-toolbar>

        <b-table
            :data="data"
            :loading="loading"
            :checkable="false"
            :hoverable="true"

            paginated
            backend-pagination
            :total="total"
            :per-page="per_page"
            @page-change="onPageChange"

            v-on:click="goToDetail"
            style="cursor: pointer"

            backend-sorting
            :default-sort="[sort_field, sort_order]"
            @sort="onSort">

          <b-table-column cell-class="vcenterplease" field="icon" class="has-text-centered" width="20" v-slot="props">
            <b-image style="height: 100%; width: 100px" :src="`data:image/png;base64,${props.row.snapshot}`" ratio="5by3"
                     src-fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Wg8AAk8BZuQcWLwAAAAASUVORK5CYII=">
            </b-image>
          </b-table-column>


          <b-table-column cell-class="vcenterplease" field="project_id" sortable label="Project" v-slot="props">
            <template v-if="props.row.project">

              <b-tag style="cursor: pointer" type="is-light" size="small" @click.native="$router.push({ name:'project.detail', params: {id: props.row.project.id}})">
                {{ props.row.project.name }}
              </b-tag>
<!--                  <p class="subtitle is-6">{{ props.row.company.name }}</p>-->
            </template>

          </b-table-column>

          <b-table-column  cell-class="vcenterplease" field="package" label="Package" v-slot="props">
            <template v-if="props.row.package">
              <b-tag v-if="props.row.package" style="cursor: pointer" type="is-info is-light" size="small" @click.native="$router.push({ name:'package.detail', params: {id: props.row.package.id, project_id: props.row.package.project_id}})">
                {{ props.row.package.name }}
              </b-tag>
            </template>
          </b-table-column>


          <b-table-column cell-class="vcenterplease" field="status" sortable label="Status" v-slot="props">
            <div v-if="props.row.status === 'pending'">
              <b-tag type="is-warning is-light">pending</b-tag>
            </div>

            <div  v-if="props.row.status === 'irrelevant'">
              <small class="has-text has-text-success-dark">irrelevant</small>
            </div>

            <div  v-if="props.row.status === 'relevant'">
              <small class="has-text has-text-danger-dark">relevant</small>
            </div>
          </b-table-column>

          <b-table-column cell-class="vcenterplease" field="timestamp" label="Happened"  sortable v-slot="props">
            <span class="has-text-grey">{{ props.row.timestamp | moment("from", "now")}}</span>
          </b-table-column>
          <b-table-column cell-class="vcenterplease" field="timestamp" label="Timestamp"  sortable v-slot="props">
            <span class="has-text-grey">{{ props.row.timestamp | moment("MMMM Do YYYY, HH:mm:ss ")}}</span>
          </b-table-column>

        </b-table>
      </card-component>
    </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "../components/TitleBar";
import packages from "@/store/modules/packages.store";
import EyeIcon from "@/components/EyeIcon";
import AlertDetailComponent from "@/components/package/AlertDetailComponent";
import CardToolbar from "@/components/CardToolbar";

export default {
  name: 'Alerts',
  components: {CardToolbar, EyeIcon, TitleBar, CardComponent },
  data () {
    return {
      data: [],
      loading: true,
      total: 0,
      page: 1,
      per_page: 25,
      sort_field: "",
      sort_order: "asc",

      selectedStatusInput: null,
      beforeInput: null,
      afterInput: null,
    }
  },

  created() {
    if(!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
  },
  computed: {
    titleStack () {
      return [
          'Admin',
        'All Alerts'
      ]
    },

  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.loading = true

      const params = [
        `include=project`,
        `filter[status]=${this.selectedStatusInput ?? 'all'}`,
        `filter[before]=${this.beforeInput ? this.beforeInput.toISOString() : 'all'}`,
        `filter[after]=${this.afterInput ? this.afterInput.toISOString() : 'all'}`,
        `page=${this.page}`,
        `per_page=${this.per_page}`,
        `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,


      ].join('&')

      this.$store.dispatch('packages/fetchAllAlerts', { params: params })
          .then(response => {
            this.data = response.data.data
            this.total = response.data.meta.total

            this.loading = false
          })
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.sort_field = field
      this.sort_order = order
      this.fetchData()
    },

    goToDetail(row) {
      let thisIns = this
      this.$buefy.modal.open({
        parent: this,
        component: AlertDetailComponent,
        props: {
          project_id: row.project ? row.project.id : null,
          alert_id: row.id
        },
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        ariaRole: 'dialog',
        width: 500,
        events: {
          refresh () {
            thisIns.fetchData(0);
          }
        }
      })    },


  }

}
</script>

<style lang="scss" scoped >
.b-table {
  .table {
    td.vcenterplease {
      vertical-align: middle;
    }
  }
}
</style>