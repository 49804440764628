<template>
<div v-if="the_package">
    <section class="section is-main-section">
        <TitleBar :title-stack="titleStack"/>

        <div class="columns  is-multiline">
          <div class="column ">

            <div class="tile is-ancestor is-vertical">
                    <div class="tile is-parent ">
                        <card-component title="Details" class="tile is-child">

                            <form @submit.prevent="submit">
                                <b-field label="Package name" horizontal>
                                    <b-field>
                                        <b-input v-model="the_package.name" placeholder="Name" name="name" required />
                                    </b-field>
                                </b-field>
                                <b-field label="Active" horizontal message="Mark if package is actively in use or not.">
                                  <b-switch v-model="the_package.active"
                                            true-value="1"
                                            false-value="0">
                                  </b-switch>
                                </b-field>
                                <b-field label="Built" horizontal>
                                    <b-datetimepicker
                                            v-model="the_package.created_at"
                                            placeholder="Click to select..."
                                            icon="calendar-today"
                                            disabled
                                            trap-focus>
                                    </b-datetimepicker>
                                </b-field>
                                <b-field label="Keycode" horizontal>
                                    <b-field>
                                        <b-input v-model="the_package.keycode" placeholder="1234" name="keycode" maxlength="4" required />
                                    </b-field>
                                </b-field>
                                <b-field label="Has battery" horizontal>
                                    <b-switch v-model="the_package.has_battery"
                                              true-value="1"
                                              false-value="0">
                                    </b-switch>
                                </b-field>

                              <b-field label="Type" horizontal message="Mark if package is of type Generic, Risco or AI">
                                <b-select v-model="the_package.type" expanded icon="shield-lock">
                                  <option value="v0">Generic</option>
                                  <option value="v1">Risco</option>
                                  <option value="v2">AIBOX</option>
                                  <option value="anpr">ANPR</option>
                                </b-select>
                              </b-field>

                                <b-field label="Monitor mode" horizontal>
                                    <b-field message="Select the method that will be used as power monitoring.">
                                        <b-select v-model="the_package.power_monitoring_mode" expanded icon="power-plug">
                                            <option value="off">Off</option>
                                            <option v-if="the_package.type === 'v1'" value="zone">Use a specific Risco Zone</option>
                                            <option v-if="the_package.type === 'v2'" value="sensor">Use a specific Sensor</option>
                                            <option value="risco">Use Risco Power</option>
                                        </b-select>
                                    </b-field>
                                </b-field>
                                <b-field v-if="the_package.power_monitoring_mode === 'zone'" label="Monitor zone" horizontal>
                                    <b-field message="You can select the zone once they are synced to the portal.">
                                        <b-select v-model="the_package.power_monitoring_zone_id" expanded icon="circle-medium">
                                            <optgroup v-for="_alarm in the_package.alarms" :label="_alarm.name">
                                                <option v-for="_zone in _alarm.partitions.map(part => part.zones).reduce((p, c) => p.concat(c).filter(zo => zo.used), [])" :value="_zone.id">{{ _zone.name }}</option>
                                            </optgroup>
                                        </b-select>
                                    </b-field>
                                </b-field>
                                <b-field v-if="the_package.power_monitoring_mode === 'sensor'" label="Monitor sensor" horizontal>
                                  <b-field message="You can select the sensor once they are synced to the portal.">
                                    <b-select v-model="the_package.power_monitoring_sensor_id" expanded icon="circle-medium">
                                      <optgroup v-for="_box in the_package.aiboxes" :label="_box.name">
                                        <option v-for="_sensor in _box.partitions.map(part => part.sensors).reduce((p, c) => p.concat(c))" :value="_sensor.id">{{ _sensor.name }}</option>
                                      </optgroup>
                                    </b-select>
                                  </b-field>
                                </b-field>
                                <b-field label="Power delay" horizontal>
                                    <b-field message="The amount of delay (minutes) before sending a 'power lost' message">
                                        <b-input v-model="the_package.minutes_until_notification" placeholder="" name="minutes_until_notification"  />
                                    </b-field>
                                </b-field>
                                <b-field label="Generator Mode" horizontal message="Mark if package has a generator running (and receive generator overtime notifications)">
                                  <b-switch v-model="the_package.generator_mode"
                                            true-value="1"
                                            false-value="0">
                                  </b-switch>
                                </b-field>
                                <b-field label="Auto download" horizontal message="Automatically download related recordings when there is a new alert/event">
                                    <b-switch v-model="the_package.autodownload"
                                              true-value="1"
                                              false-value="0">
                                    </b-switch>
                                </b-field>
                                <hr>
                                <div class="editor">
                                    <b-field>
                                        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                                            <div class="menubar">

                                                <b-field>
                                                    <p class="control">
                                                        <button class="button"
                                                                :class="{ 'is-active': isActive.bold() }"
                                                                @click="commands.bold">
                                                            <b-icon icon="format-bold"></b-icon>
                                                        </button>
                                                    </p>
                                                    <p class="control">
                                                        <button class="button"
                                                                :class="{ 'is-active': isActive.italic() }"
                                                                @click="commands.italic">
                                                            <b-icon icon="format-italic"></b-icon>
                                                        </button>
                                                    </p>

                                                    <p class="control">
                                                        <button class="button"
                                                                :class="{ 'is-active': isActive.todo_list() }"
                                                                @click="commands.todo_list">
                                                            <b-icon icon="format-list-bulleted"></b-icon>
                                                        </button>
                                                    </p>

                                                </b-field>

                                            </div>
                                        </editor-menu-bar>

                                    </b-field>


                                    <b-field>
                                        <editor-content class="textarea" :editor="editor"/>
                                    </b-field>
                                </div>
                                <hr>
                                <b-field horizontal >
                                    <b-field grouped position="is-right">
                                        <div class="control">
                                            <b-button native-type="submit" type="is-primary">Update</b-button>
                                        </div>
                                    </b-field>
                                </b-field>

                            </form>

                        </card-component>
                    </div>
                    <div class="tile is-parent ">

                        <card-component title="Current Project" class="tile is-child ">
                            <div class="media" v-if="the_package.project" style="cursor:pointer" @click="$router.push({ name:'project.detail', params: {id: the_package.project.id} })">
                                <div class="media-left">
                                    <eye-icon :status="the_package.project.status" type="project" :has-text="false"/>
                                </div>
                                <div class="media-content">
                                    <p class="title is-4">{{ the_package.project.name }}</p>
                                    <p class="subtitle is-6">{{ the_package.company.name }} </p>
                                </div>
                            </div>
                            <div v-else>
                                <p class="has-text-centered">
                                    <b-icon icon="cactus" size="is-large" />
                                </p>
                                <p class="has-text-centered">Not linked to a project</p>
                            </div>



                            <b-field v-if="the_package.project">
                                <b-button icon-left="link-variant-off" size="is-small" outlined type="is-danger" @click="unlinkProject">Remove from project</b-button>
                            </b-field>


                        </card-component>

                    </div>
            </div>
          </div>
          <div class="column">
            <div class="tile is-ancestor is-vertical">




              <div class="tile is-parent">

                        <!-- Send nases as prop (dropdown data of available nases will be fetched) -->
                        <nas-list-component v-on:update="fetchData"
                                            :package_id="the_package.id"
                                            :nases="the_package.nas"
                                            class="tile is-child">
                        </nas-list-component>
              </div>
              <div v-if="the_package.type === 'v1'" class="tile is-parent">

              <!-- Send alarms as Prop -->
                        <alarm-list-component v-on:update="fetchData"
                                              :package_id="the_package.id"
                                              :alarms="this.the_package.alarms"
                                              class="tile is-child">
                        </alarm-list-component>

              </div>
              <div v-if="the_package.type === 'v2'" class="tile is-parent">

                <!-- Send alarms as Prop -->
                <a-i-box-list-component v-on:update="fetchData"
                                      :package_id="the_package.id"
                                      :aiboxes="this.the_package.aiboxes"
                                      class="tile is-child">
                </a-i-box-list-component>

              </div>
              <div class="tile is-parent">

              <!-- Send cameras as Prop -->
                        <camera-list-component v-on:update="fetchData"
                                               :nases="this.the_package.nas"
                                               :cameras="this.the_package.cameras"
                                               class="tile is-child">
                        </camera-list-component>

              </div>

              <div class="tile is-parent">

                <card-component class="tile is-child" title="Permissions">
                  <b-message type="is-info">
                    Permissions are now based on project level, you will find them there.
                  </b-message>
                </card-component>
              </div>

              </div>
          </div>
        </div>

    </section>
  </div>

</template>

<script>
import HeroBar from '@/components/HeroBar'
import packages from "@/store/modules/packages.store"
import CardComponent from '@/components/CardComponent'
import NasListComponent from "@/components/devices/nas/NasListComponent";
import AlarmListComponent from "@/components/devices/alarm/AlarmListComponent";
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
    CodeBlock,
    HardBreak,
    Heading,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
} from 'tiptap-extensions'
import ProjectItemComponent from "../components/project/ProjectItemComponent";
import LinkPackageComponent from "../components/package/LinkPackageComponent";
import EyeIcon from "../components/EyeIcon";
import TitleBar from "../components/TitleBar";
import CameraListComponent from "../components/devices/camera/CameraListComponent";
import AIBoxListComponent from "@/components/devices/aibox/AIBoxListComponent";




const GeoJSON = require('geojson');


export default {
  name: 'PackageEdit',
  components: {
    AIBoxListComponent,
      CameraListComponent,
      TitleBar,
      EyeIcon,
      NasListComponent, AlarmListComponent, HeroBar, CardComponent, EditorContent, EditorMenuBar },
    data () {
        return {
            the_package: null,
            editor: new Editor({
                content: '',
                extensions: [
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new TodoItem({
                        nested: true,
                    }),
                    new TodoList(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                ],
            }),




        }
    },
  computed: {

    titleStack () {
      return [
        'Packages',
          'Edit'
      ]
    },
      id () {
        return parseInt(this.$route.params.id)
      },

      //...mapGetters({
      //    currentSite: 'sites/getSiteById'
      //})
  },


    created() {
        if(!packages.isRegistered) {
            this.$store.registerModule('packages', packages)
            packages.isRegistered = true
        }
    },

    beforeMount() {
        this.fetchData()
    },
    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editor.destroy()
    },

    methods: {

        fetchData() {

          let formData = {
              id: this.id,
              params: "include=project.company,alarms.partitions.zones,cameras,nas,aiboxes.partitions.sensors"
          }

            this.$store.dispatch('packages/fetchPackageDetails', formData)
                .then(response => {
                    this.the_package = response.data.data
                    this.the_package.created_at = new Date(response.data.data.created_at)
                    this.editor.setContent(JSON.parse(response.data.data.comments));

                })
        },

        submit() {
            this.the_package.comments = JSON.stringify(this.editor.getJSON())

            this.$store.dispatch('packages/updatePackage',{
                package_id: this.the_package.id,
                formData: this.the_package
            })
                .then(response => {
                    this.$buefy.toast.open({
                        message: 'Package updated',
                        type: 'is-success'
                    })

                }).catch(err => {
                this.$buefy.toast.open({
                    message: err,
                    type: 'is-danger'
                })
            })

        },

        unlinkProject() {
            this.$buefy.dialog.confirm({
                title: 'Remove from project',
                message: 'Are you sure you want to <b>remove</b> this package from this project? Logs and alerts will stay ' +
                    'in this project.',
                confirmText: 'Detach package',
                type: 'is-warning',
                hasIcon: true,
                onConfirm: () => this.unlink()
            })
        },

        unlink() {
            this.$store.dispatch('packages/updatePackage',{
                package_id: this.the_package.id,
                formData: { project_id: null }
            })
                .then(response => {
                    this.$buefy.toast.open({
                        message: 'Package removed',
                        type: 'is-success'
                    })
                    //this.fetchData()
                    this.the_package.project = null

                }).catch(err => {
                this.$buefy.toast.open({
                    message: err,
                    type: 'is-danger'
                })
            })

        }

    }
}
</script>

<style lang="scss">
    .editor__content {
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        border: 1px solid #dbdbdb;
        display: block;
        max-width: 100%;
        min-width: 100%;
        padding: 0.625em;
        resize: vertical;
        background-color: white;
        border-radius: 4px;
        min-height: 100px;
    }
    .ProseMirror:focus {
        outline: none;
    }

    .textarea {
        resize: vertical;
        height: 100%;
    }

    @import "src/scss/theme-default";

    ul[data-type="todo_list"] {
        padding-left: 0;
    }
    li[data-type="todo_item"] {
        display: flex;
        flex-direction: row;
    }
    .todo-checkbox {
        border: 2px solid $grey-dark;
        height: 0.9em;
        width: 0.9em;
        box-sizing: border-box;
        margin-right: 10px;
        margin-top: 0.3rem;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        border-radius: 0.2em;
        background-color: transparent;
        transition: 0.4s background;
    }
    .todo-content {
        flex: 1;
        > p:last-of-type {
            margin-bottom: 0;
        }
        > ul[data-type="todo_list"] {
            margin: .5rem 0;
        }
    }
    li[data-done="true"] {
        > .todo-content {
            > p {
                text-decoration: line-through;
            }
        }
        > .todo-checkbox {
            background-color: $grey-dark;
        }
    }
    li[data-done="false"] {
        text-decoration: none;
    }


</style>
