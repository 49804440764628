<template>
    <section class="section is-main-section">
        <TitleBar :title-stack="titleStack"/>

        <company-table-component>

        </company-table-component>
    </section>
</template>

<script>
import HeroBar from "../../components/HeroBar";
import CompanyTableComponent from "../../components/companies/CompaniesTableComponent";
import users from "@/store/modules/users.store"
import TitleBar from "../../components/TitleBar";

export default {
  name: 'Companies',
  components: {TitleBar, CompanyTableComponent, HeroBar },
    created() {
        if(!users.isRegistered) {
            this.$store.registerModule('users', users)
            users.isRegistered = true
        }
    },
    computed: {
        titleStack () {
            return [
                'Admin',
                'Companies'
            ]
        },

    },
}
</script>
