<template>
    <section class="section is-main-section">

        <TitleBar :title-stack="titleStack"/>

        <tiles>
            <user-form-component :user="this.user" v-on:update="fetchData" class="tile is-child"/>
            <card-component title="Profile" icon="account" class="tile is-child">
                <user-avatar class="image has-max-width is-aligned-center"/>
                <hr>
                <b-field label="Name">
                    <b-input :value="user.name" custom-class="is-static" readonly/>
                </b-field>
                <hr>
                <b-field label="E-mail">
                    <b-input :value="user.email" custom-class="is-static" readonly/>
                </b-field>
                <hr>
                <b-field label="Companies">
                    <div class="buttons">
                        <b-button size="is-small" v-for="company in user.companies" type="is-primary" @click="$router.push({name: 'companies.detail', params: { id: company.id}})">
                            <b-icon size="is-small" icon="office-building"/>
                            {{company.name }}
                        </b-button>
                        <small class="has-text-grey" v-if="user.companies.length === 0" >{{user.name }} is not linked to any companies</small>
                    </div>

                </b-field>
                <hr>
                <b-field label="Created">
                    <b-input :value="user.created_at | moment('D MMMM YYYY, HH:mm')" custom-class="is-static" readonly/>
                </b-field>

            </card-component>
        </tiles>
    </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "@/components/TitleBar";
import UsersTableComponent from "@/components/users/UsersTableComponent";
import UserAvatar from "../../components/UserAvatar";
import HeroBar from "../../components/HeroBar";
import users from "@/store/modules/users.store"
import UserFormComponent from "../../components/users/UserFormComponent";
import Tiles from '@/components/Tiles'



export default {
  name: 'Users',
  components: { Tiles, UserFormComponent, UserAvatar, UsersTableComponent, TitleBar, CardComponent },
    data() {
        return {
            user: {},
            titleStack: ['Users']
        }
    },

    computed: {
      id () {
          return parseInt(this.$route.params.id)
      },

  },
    created() {
        if(!users.isRegistered) {
            this.$store.registerModule('users', users)
            users.isRegistered = true
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        fetchData() {
            this.loading = true

            const payload = {
                id: this.id,
                params: 'include=projects'
            }
            console.log(payload)

            this.$store.dispatch('users/fetchUserDetails', payload)
                .then(response => {
                    this.user = response.data
                    this.titleStack.push(this.user.name)
                    this.loading = false
                })
            .catch(err => {
                console.log(err)
            })

        },

    }

}
</script>
