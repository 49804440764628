<template>
    <section class="section is-main-section">

        <title-bar :title-stack="titleStack"/>

        <card-component class="has-table has-mobile-sort-spaced">
          <camera-table-component>

          </camera-table-component>
      </card-component>
    </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "@/components/TitleBar";
import CameraTableComponent from "@/components/devices/camera/CameraTableComponent";

export default {
  name: 'Cameras',
  components: {CameraTableComponent, TitleBar, CardComponent },
  computed: {
    titleStack () {
      return [
          'Admin',
          'Devices',
          'Cameras'
      ]
    }
  }
}
</script>
