<template>
  <card-component :title="this.creating ? 'Create Camera' : 'Edit Camera'"
                  :icon="this.creating ? 'plus-circle' : 'pencil'"
                  :is-scrollable="true">

    <form @submit.prevent="submit">
      <b-field label="Name" horizontal>
        <b-field>
          <b-input v-model="camera.name" name="name"/>
        </b-field>
      </b-field>
      <b-field label="IP & port" horizontal
               message="Note: enter the web_ui port and the port of the web interface. A second forwarding rule in the router is necessary to forward the rtsp port to the camera port 554">
        <b-field>
          <b-input v-model="camera.ip" placeholder="192.168.64..." required></b-input>
          <p class="control">
            <span class="button is-static">web ui</span>
          </p>
          <b-input expanded v-model="camera.port" placeholder="8000" required></b-input>
          <p class="control">
            <span class="button is-static">rtsp</span>
          </p>
          <b-input expanded v-model="camera.rtsp_port" placeholder="8001"></b-input>

        </b-field>
      </b-field>

      <b-field label="Login" horizontal>
        <b-field>
          <b-input v-model="camera.username" placeholder="admin" name="username"/>
        </b-field>
        <b-field>
          <b-input v-model="camera.password" type="password" password-reveal name="username"/>
        </b-field>
      </b-field>
      <b-field label="Type" horizontal>
        <b-field>
          <b-input v-model="camera.type" placeholder="WV-23RE" name="type"/>
        </b-field>
      </b-field>
      <b-field label="Package ID" horizontal>
        <b-field message="Warning! Use sync. Only necessary for 'anpr' type cameras or dev purposes.">
          <b-numberinput :disabled="camera.type !== 'anpr'" v-model="camera.package_id" name="package_id"/>
        </b-field>
      </b-field>
      <b-field label="Mac" horizontal>
        <b-field>
          <b-input v-model="camera.mac" name="mac"/>
        </b-field>
      </b-field>

      <b-field v-if="camera.nas_id">
        <b-field label="Nimble Stream Source" horizontal>
          <b-message>
            A temporary URL for the Nimble stream will be created. Make sure that the Cam (or nas) RTSP URL is
            configured
            as RTSP pull source using the following details: <br> app: <b>portal</b>, stream: <b>cam{{ camera.id }}</b>
            This can be done automatically by clicking 'Sync Stream' on the PackageEdit page.
          </b-message>
        </b-field>
        <b-field horizontal
        >
          <b-select v-model="camera.stream_mode" expanded icon="video-box">
            <option value="nas rtsp">Use Surveillance Station RTSP</option>
            <option value="cam rtsp">Use Direct Cam RTSP</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field v-else label="Stream Source" horizontal>
        <b-message>
          Since this camera is fetched from an AIBOX, streaming will happen from there.
        </b-message>
      </b-field>

      <b-field v-if="camera.stream_mode === 'cam rtsp'" label="Stream that will be used (Panasonic camera)" horizontal>
        <b-field>
          <b-select v-model="camera.stream" name="stream">
            <option value="stream_1">Stream 1</option>
            <option value="stream_2">Stream 2</option>
            <option value="stream_3">Stream 3</option>
            <option value="stream_4">Stream 4</option>
            <option value="">empty</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field horizontal>
        <b-field grouped position="is-right">
          <div class="control">
            <b-button type="is-primary is-outlined" @click="this.$parent.close">Cancel</b-button>
          </div>
          <div class="control">
            <b-button native-type="submit" type="is-primary">{{ this.creating ? "Create" : "Update" }}</b-button>
          </div>
        </b-field>
      </b-field>

    </form>
  </card-component>
</template>

<script>
import devices from "@/store/modules/devices.store"
import CardComponent from "@/components/CardComponent";

export default {
  name: "Nas",
  components: {CardComponent},
  props: {
    id: {
      default: null
    },
  },
  data() {
    return {
      camera: {}

    }
  },

  computed: {
    creating() {
      return this.id === null
    }

  },
  created() {
    if (!devices.isRegistered) {
      this.$store.registerModule('devices', devices)
      devices.isRegistered = true
    }
  },

  mounted() {
    if (this.id === null) {
      this.camera = {
        name: "",
        ip: "",
        port: 8000,
        rtsp_port: 8001,
        stream: "stream_1",
        synodns: "",
        username: "",
        password: "",
        type: "",
        mac: "",
        serialnumber: "",

      }
    } else {
      // Fetch camera data
      this.$store.dispatch('devices/fetchCameraDetails', this.id)
        .then(response => {
          this.camera = response.data.data
        })
    }
  },

  methods: {

    submit() {
      // Add new camera
      if (this.creating) {
        this.$store.dispatch('devices/createCamera', this.camera)
          .then(response => {
            this.$emit("refresh")
            this.$parent.close()
            this.$buefy.toast.open({
              message: 'Camera created',
              type: 'is-success'
            })

          }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })
      }
      // Update existing camera
      else {
        this.$store.dispatch('devices/updateCamera', this.camera)
          .then(response => {
            this.$emit("refresh")
            this.$parent.close()
            this.$buefy.toast.open({
              message: 'Camera updated',
              type: 'is-success'
            })

          }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })
      }

    }
  }

}
</script>

<style scoped lang="scss">


</style>
