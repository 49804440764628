<template>
    <card-component class=" has-mobile-sort-spaced" :has-button-slot="true" title="Companies" icon="office-building">
        <div slot="button" class="buttons">
            <b-button v-can="'manage all companies'" @click="createModal(null)" type="is-grey" size="is-small" icon-left="plus-circle">
                Create
            </b-button>
            <refresh-button slot="button" :isLoading="loading" @button-click="fetchData"/>
        </div>
        <b-table
                :data="data"
                :loading="loading"
                :checkable="false"
                :striped="true"
                :hoverable="true"
                searchable>

            <b-table-column field="name" label="Name" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="users_count" label="Users" sortable v-slot="props">
                <b-taglist attached>
                    <b-tag type="is-primary"><b-icon size="is-small" icon="account-multiple"/></b-tag>
                    <b-tag type="is-primary">{{ props.row.users_count }}</b-tag>
                </b-taglist>
            </b-table-column>
            <b-table-column field="projects_count" label="Projects" sortable v-slot="props">
                <b-taglist attached>
                    <b-tag type="is-primary"><b-icon size="is-small" icon="map-marker"/></b-tag>
                    <b-tag type="is-primary">{{ props.row.projects_count }}</b-tag>
                </b-taglist>
            </b-table-column>

            <b-table-column field="created_at" label="Created" sortable v-slot="props">
                {{ props.row.created_at | moment("from", "now")}}
            </b-table-column>

            <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
                <div class="buttons is-right">

                    <b-button icon-left="eye" size="is-small" type="is-success" @click="$router.push({name: 'companies.detail', params: { id: props.row.id}})"></b-button>

                    <b-dropdown v-can="'manage all companies'" append-to-body aria-role="list" position="is-bottom-left">
                        <b-button icon-left="menu" size="is-small" type="is-primary" slot="trigger" slot-scope="{ active }">
                        </b-button>

                        <b-dropdown-item aria-role="listitem" >
                            <div class="media" @click="deleteModal(props.row.id)">
                                <b-icon class="media-left" icon="delete"></b-icon>
                                <div class="media-content">
                                    <h3>Remove</h3>
                                </div>
                            </div>
                        </b-dropdown-item>

                    </b-dropdown>

                </div>
            </b-table-column>



        </b-table>
    </card-component>
</template>

<script>
    import CardComponent from "@/components/CardComponent";
    import RefreshButton from '@/components/RefreshButton'
    import UserAvatar from "../UserAvatar";


    export default {
        name: "CompanyTableComponent",
        components: {UserAvatar, CardComponent, RefreshButton },

        data () {
            return {
                data: [],
                loading: true,
                total: 0,
                page: 1,
                per_page: 10,
                sort_field: "",
                sort_order: "asc",
            }
        },



        mounted() {
            this.fetchData()
        },

        methods: {

            fetchData() {
                this.loading = true

                const params = [
                    'include=usersCount,projectsCount',
                //     `page=${this.page}`,
                //     `per_page=${this.per_page}`,
                //     `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,
                //
                ].join('&')
                // console.log(params)

                this.$store.dispatch('users/fetchCompanies', params)
                    .then(response => {
                        this.data = response.data
                        this.loading = false
                    })
            },

            deleteModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Removing Company',
                    message: 'Are you sure you want to <b>remove</b> this company? This action cannot be undone.',
                    confirmText: 'Remove Company',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('users/deleteCompany', {id: id})
                            .then(response => {
                                this.$buefy.toast.open('Company removed!')
                            }).catch(err => {
                             this.$buefy.toast.open('Could not delete company')
                        })
                    }
                })
            },
            createModal() {
                this.$buefy.dialog.prompt({
                    message: `What's the company name?`,
                    inputAttrs: {
                        placeholder: 'e.g. MyCompany',
                        maxlength: 50
                    },
                    trapFocus: true,
                    onConfirm: (value) => this.submit({name: value})
                })
            },
            submit(value) {
                this.$store.dispatch('users/createCompany', value)
                    .then(response => {
                        this.$buefy.toast.open({
                            message: 'Company created',
                            type: 'is-success'
                        })
                        this.$router.push({name: 'companies.detail', params: { id: response.data.id}})

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                })
            }

        }




    }
</script>

<style scoped>

</style>